import Tournaments from "../server/services/data-layer/modules/sapi/tournaments"
import settings from "../settings"
import { ITournamentMatchup, TPlaysInto, TSportType } from "./db-typings"
import { GameSportType } from "./game-enums"

const buildTournamentStartTimesFor = (sportYear: number) => {
  const adjustedYear = settings.USE_PREV_YEAR_TOURNAMENTS ? sportYear + 2 : sportYear + 1
  return {
    "Atlantic Coast Conference Tourney": `4:30 pm GMT-0400 March 9, ${adjustedYear}`,
    "Big East Conference Tourney": `7:00 pm GMT-0400 March 10, ${adjustedYear}`,
    "Big Ten Tourney": `6:00 pm GMT-0400 March 10, ${adjustedYear}`,
    "Big 12 Conference Tourney": `7:00 pm GMT-0400 March 13, ${adjustedYear}`,
    "Pac-12 Conference Tournament": `3:00 pm GMT-0400 March 13, ${adjustedYear}`,
    "Southeastern Conference Tourney": `7:00 pm GMT-0400 March 14, ${adjustedYear}`,
  }
}

function championshipGameName(sportType: TSportType) {
  // these values match the Primpy values: we need them to help identify specific games
  if (sportType === GameSportType.values.NCAAF.value) {
    return "NCAAF Championship"
  }
  if (sportType === GameSportType.values.NFL.value) {
    return `NFL Champion`
  }
  return `Tournament Winner`
}

const NcaaTournament = {
  _id: 1,
  name: "NCAA Tournament",
}
const NcaawTournament = {
  _id: 53,
  name: "Women's NCAA Tournament",
}

const defaultNcaaTournamentRoundBonuses = [1, 2, 4, 8, 16, 32]
const defaultNcaaTournamentSweetSixteenRoundBonuses = [4, 8, 16, 32] // NOTE/S16 TODO LL: Confirm with product this scores like this

function getMatchupSportYearFor(seasonDbRow) {
  // NOTE qac: we have to decide between whether we use simSportYear or sportYear for Matchup... touches a bunch of places, so lets centralize that here in case we need to change it
  const sportYear = seasonDbRow.sportYear
  // const extra = getExtraColumn(segmentDbRow)
  // const sportYear = extra.simulateYear || seasonDbRow.sportYear
  return sportYear
}

function getGroupPosition(
  roundOrdinal: number,
  tournamentRound: number,
  matchupsPerRound: number,
  maxRound: number,
  isPlayin: boolean,
): ITournamentMatchup["groupPosition"] {
  const groupI = Math.floor(roundOrdinal / (matchupsPerRound / 4))
  const isAboveSemis = tournamentRound >= maxRound - 1
  const groupPosition =
    (isPlayin && "top") ||
    (isAboveSemis && "bottom") ||
    (groupI === 0 && "top_left") ||
    (groupI === 1 && "bottom_left") ||
    (groupI === 2 && "top_right") ||
    (groupI === 3 && "bottom_right") ||
    null
  if (!groupPosition) {
    throw new Error(`no groupPosition for ${roundOrdinal} ${tournamentRound}`)
  }
  return groupPosition
}

const intoTop = "top" as TPlaysInto
const intoBot = "bottom" as TPlaysInto
function buildNcaaTournamentMatchups(sportYear: number, isWomenTournament = false) {
  // TODO qac: i know there is a better way to do this mathmatically...
  const seedMapping = [1, 16, 8, 9, 5, 12, 4, 13, 6, 11, 3, 14, 7, 10, 2, 15]
  const matchups = [] as ITournamentMatchup[]
  const tournamentId = isWomenTournament ? NcaawTournament._id : NcaaTournament._id
  const tournamentDescription = isWomenTournament ? NcaawTournament.name : NcaaTournament.name
  let tournamentRound = 1
  const totalRound2Matchups = 8 * 4
  while (tournamentRound < 8) {
    const adjRoundI = Math.max(0, tournamentRound - 2) // 0 - 6
    const isPlayin = tournamentRound === 1
    const reduceBy = Math.pow(2, adjRoundI)
    const matchupsPerRound = isPlayin ? 4 : totalRound2Matchups / reduceBy
    let roundOrdinal = 0
    while (roundOrdinal < matchupsPerRound) {
      const topItemSeed = tournamentRound === 2 ? seedMapping[(roundOrdinal % 8) * 2 + 0] : undefined
      const bottomItemSeed = tournamentRound === 2 ? seedMapping[(roundOrdinal % 8) * 2 + 1] : undefined
      const winnerPlaysIntoOrdinal = tournamentRound > 1 && tournamentRound < 7 ? Math.floor(roundOrdinal / 2) : undefined
      const winnerPlaysIntoPosition = winnerPlaysIntoOrdinal === undefined ? undefined : roundOrdinal % 2 === 1 ? intoBot : intoTop
      const groupPosition = getGroupPosition(roundOrdinal, tournamentRound, matchupsPerRound, 7, isPlayin)
      const groupName = (groupPosition === "top" && "First Four") || (groupPosition === "bottom" && "Final Four") || null
      // console.log(`creating: ${totalRound2Matchups}:${tournamentRound}:${matchupsPerRound}:${roundOrdinal}:${reduceBy}`)
      matchups.push({
        isPlayin,
        tournamentId,
        tournamentDescription,
        tournamentRound,
        roundOrdinal,
        winnerPlaysIntoOrdinal,
        winnerPlaysIntoPosition,
        topItemSeed,
        bottomItemSeed,
        groupPosition,
        groupName,
        sportYear,
        subsection: tournamentRound >= 4 ? "NcaaTournamentSweetSixteen" : null,
      })
      roundOrdinal = roundOrdinal + 1
    }
    tournamentRound = tournamentRound + 1
  }
  return matchups
}

export {
  Tournaments,
  defaultNcaaTournamentRoundBonuses,
  defaultNcaaTournamentSweetSixteenRoundBonuses,
  buildTournamentStartTimesFor,
  championshipGameName,
  NcaaTournament,
  NcaawTournament,
  buildNcaaTournamentMatchups,
  getMatchupSportYearFor,
}
