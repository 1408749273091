import React, { FC, useRef, useState, useCallback, useMemo, useEffect } from "react"
import { Field, FormikProps, useField } from "formik"
import styled from "styled-components"
import { fontFamily, fontWeights, pxToRem, buildClassNames as cx, fontWeight } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import RadioButton from "@cbs-sports/sports-shared-client/build/cjs/components/RadioButton"
import bracketTheme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import PhotoSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Photo"
import { inputBgColorFor, inputBorderFor, inputFocusStyleFor } from "../../../../common/form-theme-helpers"
import { IFullPoolSettings } from "../../../../common/pool-settings"
import { IValidationErrorBody } from "../../../../common/apiErrors"
import { errorClassFor } from "../../../components/Form"
import PoolAvatarForm from "../../PoolPages/containers/MultipleEntriesArea/PoolAvatarForm"
import { PoolDetailsQuery_pool } from "../../../../__generated__/PoolDetailsQuery"
import { getFullPoolSettings, getSegment, tournamentRBLabels } from "../../../../common/pool-settings-setup"
import { sortByOrder } from "../../../../common/sorters"
import { ENUM_CONFERENCE_TOURNAMENT, GameTypeEnum, RoundModifierTypeEnum, TiebreakerTypeEnum } from "../../../../common/enums"
import { RoundModifierType } from "../../../../__generated__/globalTypes"
import { compareArrays } from "../../../../common/misc-utils"
import ModalWrapper from "@cbs-sports/sports-shared-client/build/cjs/components/Modal/ModalWrapper/ModalWrapper"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button/Button"
import BackSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Back"
import PencilSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Pencil"
import Checkbox from "@cbs-sports/sports-shared-client/build/cjs/components/Checkbox"
import Select from "@cbs-sports/sports-shared-client/build/cjs/components/Select"
import Modal from "@cbs-sports/sports-shared-client/build/cjs/components/Modal"
import constants from "../../../../common/constants"
import palette from "../../../../common/palette"
import { trackActionByKey } from "../../PoolPages/containers/MessageBoard/message-board-tracking"
import { LockSvg } from "@cbs-sports/sports-shared-client/build/cjs/components/icons"
import Globe from "../../../components/icons/Globe"
import NewIcon from "../../../components/icons/New"
import { breakpoints } from "../../../utils/style-utils"
import { useDeviceType } from "../../../Base/DeviceType"
import ConfirmDialog from "../../../components/ConfirmDialog"

/**
 * Utility bracket pool functions
 */
const bonusOptions = [
  {
    value: RoundModifierTypeEnum.NONE,
    label: "NO BONUS",
  },
  {
    value: RoundModifierTypeEnum.ADD_SEED,
    label: "PTS + SEED",
  },
  {
    value: RoundModifierTypeEnum.MULTIPLY_SEED,
    label: "PTS x SEED",
  },
]

export const getModifierLabel = (modifier: RoundModifierTypeEnum): string => {
  switch (modifier) {
    case RoundModifierTypeEnum.ADD_SEED:
      return "Points + Seed"
    case RoundModifierTypeEnum.MULTIPLY_SEED:
      return "Points x Seed"
    default:
      return "—"
  }
}

/**
 * Utility bracket pool form types
 */
export interface IUpsertBracketPoolGeneralFields {
  poolId?: string
  seasonId: string
  name?: string
  poolSettings?: Partial<IFullPoolSettings>
  password?: string
  usesMagicLink?: boolean
  slogan?: string
  avatarUrl?: string
}

type UpsertBracketPoolSettingsType = Pick<
  IFullPoolSettings,
  "maxEntriesPerUser" | "mainTiebreaker" | "roundBonuses" | "roundModifiers" | "includeMessageBoard"
>

export interface IUpsertBracketPoolRulesFields {
  poolId?: string
  seasonId: string
  constitution?: string
  poolSettings?: Partial<UpsertBracketPoolSettingsType>
}

export type FullUpsertBracketPoolType = IUpsertBracketPoolGeneralFields & IUpsertBracketPoolRulesFields

/**
 * Utility bracket pool form components
 */
const Row = styled.div`
  --align-label: initial;
  --grid-template-columns: repeat(11, 1fr);
  --grid-template-rows: 1fr;
  --label-column-start: 1;
  --label-column-end: 4;
  --field-column-start: 4;
  --field-column-end: -4;

  display: grid;
  grid-template-columns: var(--grid-template-columns);
  grid-template-rows: var(--grid-template-rows);
  column-gap: 1rem;
  flex: 1;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  &.row--with-helper {
    --grid-template-rows: 1fr, 1.25rem;
  }

  &.row--full-width {
    --grid-template-columns: repeat(6, 1fr);
    --label-column-start: 1;
    --label-column-end: 3;
    --field-column-start: 3;
    --field-column-end: -1;
  }
  &.is-error-message {
    padding-bottom: 0.5rem;
  }
  &.is-radio-group {
    padding-top: 0;
  }

  &.row--top-aligned {
    --align-label: flex-start;
  }

  &.row--multi-line {
    margin-bottom: 0.5rem;
  }

  .row__label {
    grid-column-start: var(--label-column-start);
    grid-column-end: var(--label-column-end);
    cursor: initial;
    margin-right: ${pxToRem(8)};
    font-weight: ${fontWeights.bold};
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(20)};
    letter-spacing: -0.1px;
    color: ${bracketTheme.colors.overLight.white20};
    align-self: var(--align-label);
  }

  .row__label--required::after {
    content: "*";
    display: inline-block;
  }

  .row__input {
    grid-column-start: var(--field-column-start);
    grid-column-end: var(--field-column-end);
    padding: ${pxToRem(10)} ${pxToRem(16)};
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(16)};
    border-radius: ${pxToRem(4)};
    color: ${bracketTheme.colors.overLight.white20};
    font-weight: ${fontWeights.regular};
    display: block;
    outline: none;
    width: 100%;
    border: ${pxToRem(1)} solid ${inputBorderFor};
    background-color: ${inputBgColorFor};
    transition: all 0.2s ease;

    &:focus {
      ${inputFocusStyleFor};
    }

    &::placeholder {
      color: ${bracketTheme.colors.overLight.white50};
      font-weight: ${fontWeights.regular};
    }
  }

  .row__input:focus.has-errors {
    border: ${pxToRem(1)} solid ${inputBorderFor};
  }

  .row__input:focus + .helper__section {
    &.show__count {
      flex-direction: row-reverse;
    }
    & > .text--messgage.hide--true {
      display: block;
    }
    & > .text--messgage.is-error {
      display: none;
    }
  }

  .helper__section {
    grid-column-start: var(--field-column-start);
    grid-column-end: var(--field-column-end);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 1rem;

    & > .text--messgage {
      font-family: ${fontFamily.base};
      font-size: 12px;
      font-style: normal;
      font-weight: ${fontWeight.regular};
      line-height: 12px;
      letter-spacing: -0.1px;
      text-align: left;
      color: ${palette.gray50};
    }

    & > .text--messgage.is-error {
      color: ${palette.darkRed};
    }
    & > .text--messgage.hide--true {
      display: none;
    }
  }
  .helper__section.show__count {
    flex-direction: row-reverse;
  }
  .helper__section.is-error {
    flex-direction: row;
  }

  .row__input.has-errors {
    border-color: ${bracketTheme.colors.overLight.red};
  }

  .row__radioGroup {
    grid-column-start: var(--field-column-start);
    grid-column-end: var(--field-column-end);

    & > * {
      margin-top: ${pxToRem(16)};

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .row__generic {
    grid-column-start: var(--field-column-start);
    grid-column-end: var(--field-column-end);
  }

  .row__textWrapper {
    grid-column-start: var(--field-column-start);
    grid-column-end: var(--field-column-end);
    font-weight: ${fontWeights.regular};
    font-size: ${pxToRem(12)};
    line-height: 1rem;
    letter-spacing: -0.5px;
    color: ${bracketTheme.colors.overLight.white50};

    &.is-error-message {
      color: ${palette.darkRed};
    }
  }

  .row__selectWrapper {
    max-width: 4rem;
  }

  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    &.row--full-width {
      --grid-template-columns: repeat(6, 1fr);
      --label-column-start: 1;
      --label-column-end: -1;
      --field-column-start: 1;
      --field-column-end: -1;
    }

    .row__label {
      margin-right: 0;
      margin-bottom: ${pxToRem(8)};
    }

    .row__textWrapper {
      text-align: center;
    }
  }
`

const CustomCheckbox = styled(Checkbox)`
  && {
    display: flex;
    position: relative;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1.25rem;
    clear: both;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: -0.1px;
    color: ${bracketTheme.colors.overLight.white20};
    font-weight: ${bracketTheme.fonts.weights.regular};
    padding: initial;

    & > .label-content {
      margin-left: 2.25rem;
    }
  }
`

const CustomRadioButton = styled(RadioButton)`
  && {
    display: flex;
    position: relative;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1.25rem;
    clear: both;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: -0.1px;
    color: ${bracketTheme.colors.overLight.white20};
    font-weight: ${bracketTheme.fonts.weights.regular};
    padding: initial;

    & > .label-content {
      margin-left: 2.25rem;
    }
  }
`
const ScoringTable = styled.div`
  border: 1px solid ${palette.gray90};
  border-radius: 0.25rem;
  overflow: hidden;

  .scoringTable__title {
    padding: 1rem;
    font-family: ${fontFamily.base};
    font-size: 1rem;
    font-style: normal;
    font-weight: ${fontWeight.bold};
    line-height: 1;
    letter-spacing: -0.1px;
    text-align: left;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${palette.gray90};
    color: ${palette.gray20};

    & > svg {
      width: 1.25rem;
      height: 1.25rem;
      color: ${palette.lightBlue3};
      cursor: pointer;
    }
  }
`

const AvatarFieldWrap = styled.div`
  & > .input-section {
    flex: 4;
    display: flex;
    flex-direction: row;
    align-items: center;

    & > .custom-upload {
      display: flex;
      flex-direction: row;
      align-items: center;

      & > .input {
        display: none;
      }

      & > .avatar {
        width: 3.75rem;
        height: 3.75rem;
        overflow: hidden;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: ${palette.gray97};

        & > img {
          height: auto;
          max-width: 100%;
          vertical-align: top;
          transition: none;
        }

        & > svg {
          width: 1.5rem;
          height: 1.5rem;
          color: ${palette.gray50};
        }

        & > .avatar-hover {
          display: none;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          align-items: center;
          justify-content: center;

          & > svg {
            width: 1.5rem;
            height: 1.5rem;
            color: ${palette.lightBlue3};
          }
        }
      }

      @media (hover: hover) {
        &:hover:not(:active) {
          & > .avatar {
            background-color: ${palette.lightestBlue};

            & > svg {
              color: ${palette.lightBlue3};
            }
          }

          & > .avatar.with-image {
            & > img {
              opacity: 0.2;
              transition: none;
            }

            & > svg {
              color: ${palette.lightBlue3};
            }

            & > .avatar-hover {
              display: flex;
            }
          }
        }
      }
    }

    & > .description {
      font-family: ${fontFamily.base};
      font-size: 1rem;
      color: ${palette.gray20};
      font-style: normal;
      line-height: ${pxToRem(19)};
      letter-spacing: 0;
      text-align: left;
      margin-left: 1rem;
    }
  }
`
const OpenInvitesContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;

  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    gap: 0.5rem;
  }
`
const OpenInvitesButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #d9dbde;
  border-radius: 0.5rem;
  width: ${pxToRem(195)};
  min-width: ${pxToRem(195)};
  height: ${pxToRem(95)};
  padding: 0 0.5rem;
  cursor: pointer;
  color: ${palette.uiSubText1};
  margin-right: 1rem;
  &.selected {
    color: ${bracketTheme.colors.overLight.white20};
    outline: 2px solid ${palette.primaryActive};
    border-color: transparent;
  }
  & > .icon-container {
    width: 1.5rem;
    height: 1.5rem;
  }

  & > .new-icon {
    position: absolute;
    top: ${pxToRem(6)};
    left: ${pxToRem(144)};
  }

  & > .title {
    font-weight: ${fontWeight.bold};
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }
  & > .subtitle {
    font-weight: ${fontWeight.semiBold};
    color: ${palette.uiSubText1};
    font-size: 0.75rem;
    margin-top: 0.3rem;
    text-align: center;
  }

  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    width: auto;
    min-width: 0;
    flex: 1;
    margin-right: 0;
  }
`

const RoundBonusTableWrap = styled.table`
  width: 100%;
  box-sizing: border-box;
  -webkit-border-horizontal-spacing: 0;
  -webkit-border-vertical-spacing: 0;

  & > thead {
    & > tr {
      text-align: right;
      font-family: ${fontFamily.condensed};
      font-size: 0.75rem;
      font-style: normal;
      font-weight: ${fontWeight.medium};
      line-height: 1;
      letter-spacing: 1.25px;
      color: ${palette.gray50};

      & > th {
        align-items: center;
        border-bottom: 1px solid ${palette.gray90};
        padding: 0.5rem 0;
      }
      & > th.all-caps {
        text-transform: uppercase;
      }

      & > th:first-child {
        text-align: left;
        padding-left: 1rem;
      }
      & > th:last-child {
        padding-right: 1rem;
      }
    }
  }
  & > tbody {
    & > tr {
      text-align: right;
      font-family: ${fontFamily.base};
      font-size: ${pxToRem(14)};
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.1px;
      color: ${palette.gray20};
      font-weight: ${fontWeight.regular};
      text-align: right;

      & > td {
        padding-bottom: 0.5rem;
        align-items: center;
      }

      & > td:first-child {
        padding-left: 1rem;
        font-weight: ${fontWeight.medium};
        text-align: left;
      }
      & > td:last-child {
        padding-right: 1rem;
      }
    }

    & > tr:first-child {
      & > td {
        padding-top: 1rem;
      }
    }
    & > tr:last-child {
      & > td {
        padding-bottom: 1rem;
      }
    }
  }

  &.mode--edit {
    border: 1px solid ${palette.gray90};
    border-radius: 0.25rem;

    & > thead {
      & > tr {
        & > th {
          border-bottom: 0;
        }
        & > th:not(:first-child) {
          text-align: center;
          padding: 0 0.5rem;
        }
      }
    }

    & > tbody {
      & > tr {
        & > td {
          padding: 0.5rem;
          border-top: 1px solid ${palette.gray90};
          vertical-align: middle;

          & > input[type="number"] {
            width: 2.5rem;
            height: 1.5rem;
            border: 1px solid ${palette.gray90};
            border-radius: 2px;
            text-align: center;
            font-weight: ${fontWeight.bold};
            font-size: 1rem;

            &:focus {
              outline: 1px solid ${palette.lightBlue3};
            }
          }
        }
        & > td:not(:first-child) {
          text-align: center;
        }
        & > td.label {
          max-width: 6.5rem;
          text-overflow: ellipsis;
          overflow: hidden;
          box-sizing: border-box;
        }
        & > td.input-text > input {
          &:focus {
            outline: none;
            border-color: ${palette.lightBlue3};
          }
        }

        & > td.option > .option-content {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
`

const RulesModalWrap = styled.div`
  box-sizing: border-box;
  width: 30.5rem;
  position: relative;

  & .header {
    font-size: 1.25rem;
    font-weight: ${fontWeight.bold};
    line-height: 1.5rem;
    text-transform: uppercase;
    color: ${palette.gray20};
  }

  & > .bonus-modifier {
    padding: 0 2.5rem 1.5rem;
    & > .header {
      display: flex;
      flex-direction: row;
      padding: 1.5rem 0;
      box-sizing: border-box;
    }

    & > .content {
      margin-bottom: 1.5rem;

      & .information {
        display: flex;
        flex-direction: column;
        margin-top: 0.75rem;
        font-family: ${fontFamily.base};
        font-size: ${pxToRem(14)};
        font-style: normal;
        font-weight: ${fontWeight.regular};
        letter-spacing: 0;
        text-align: left;

        & > .description {
          line-height: 1.25rem;
          color: ${palette.gray40};
        }
        & > .rules-link {
          margin-top: 0.5rem;
          line-height: 18px;
          color: ${palette.lightBlue3};
          font-weight: ${fontWeight.medium};
          cursor: pointer;
        }
      }
    }

    & > .footer {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      & > .right {
        display: flex;
        flex-direction: row;

        & > button:first-child {
          margin-right: 1rem;
        }
      }
    }
  }

  & > .bonus-modifier-description {
    padding: 0rem 2.5rem 2.5rem;
    font-family: ${fontFamily.base};
    font-size: 14px;
    font-style: normal;
    line-height: 1.25rem;
    letter-spacing: 0;
    text-align: left;

    & > .header {
      display: flex;
      flex-direction: row;
      padding: 1.5rem 0;
      box-sizing: border-box;
      font-size: 1.25rem;
      font-weight: ${fontWeight.bold};
      line-height: 1.5rem;
      text-transform: uppercase;
      color: ${palette.gray20};

      > svg {
        width: 1.5rem;
        height: 1.5rem;
        color: ${palette.gray50};
        margin-right: 1.25rem;
        cursor: pointer;
      }
    }

    & > .subtitle {
      color: ${palette.gray20};
      font-weight: ${fontWeight.bold};
      letter-spacing: -0.1px;
    }

    & > .rule-description {
      display: flex;
      flex-direction: column;
      margin-top: 0.75rem;
      padding: 1rem;
      border: 1px solid ${palette.gray90};
      box-sizing: border-box;
      border-radius: 0.25rem;

      & > .rule-name {
        font-size: 14px;
        line-height: 1;
        font-weight: ${fontWeight.medium};
        letter-spacing: -0.1px;
        color: ${palette.gray20};
      }
      & > .rule {
        font-weight: ${fontWeight.regular};
        margin-top: 0.25rem;
        color: ${palette.gray40};
        font-size: 14px;
        line-height: 1.25rem;
      }
    }
    & > .disclaimer {
      margin-top: 0.75rem;
      font-size: 0.75rem;
      display: flex;
      font-weight: ${fontWeight.regular};
      line-height: 1rem;
      letter-spacing: -0.1px;
      color: ${palette.gray40};
    }
  }

  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    width: 100vw;
  }
`

interface ILayoutSettings {
  isTopAligned?: boolean
  isfullWidth?: boolean
}

interface IFormTextInputProps extends ILayoutSettings {
  name: string
  id: string
  label: string
  className?: string
  placeholder?: string
  required?: boolean
  isMultiline?: boolean
  helperText?: string
  errorMessage?: string
  showCount?: boolean
  focusOnMount?: boolean
}

type FormTextInputProps = IFormTextInputProps & React.InputHTMLAttributes<HTMLInputElement> & React.TextareaHTMLAttributes<HTMLTextAreaElement>
const FormTextInput: FC<FormTextInputProps> = ({
  label,
  name,
  id,
  className = "",
  placeholder = "",
  required = false,
  isMultiline = false,
  isTopAligned,
  isfullWidth,
  helperText,
  errorMessage,
  showCount,
  focusOnMount,
  ...rest
}) => {
  const { maxLength } = rest
  const showHelperRow = !!helperText || !!errorMessage || showCount
  const [field] = useField(name)
  const fieldRef = useRef<HTMLElement | null>(null)

  useEffect(() => {
    if (focusOnMount) {
      fieldRef.current?.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Row
      className={cx({
        "row--top-aligned": isTopAligned,
        "row--multi-line": isMultiline,
        "row--full-width": isfullWidth,
        "row--with-helper": showHelperRow,
        "row--with-error": !!errorMessage,
      })}
    >
      <label htmlFor={id} className={cx({ row__label: true, "row__label--required": required })}>
        {label ?? name}
      </label>
      <Field
        name={name}
        id={id}
        className={cx({
          row__input: true,
          [`${className}`]: !!className,
          "has-errors": !!errorMessage,
        })}
        autoComplete="off"
        placeholder={placeholder}
        as={isMultiline ? "textarea" : "input"}
        {...rest}
        innerRef={fieldRef}
      />
      {showHelperRow && (
        <div
          className={cx({
            helper__section: true,
            show__count: showCount,
            "is-error": !!errorMessage,
          })}
        >
          {showCount && (
            <span className={`text--messgage hide--${!!errorMessage}`} data-cy={`pool-${name}-cnt`}>{`${
              field.value?.length || 0
            }/${maxLength}`}</span>
          )}
          {!!helperText && (
            <span className={`text--messgage hide--${!!errorMessage}`} data-cy={`pool-${name}-msg`}>
              {helperText}
            </span>
          )}
          {errorMessage && (
            <span className="text--messgage is-error" data-cy={`pool-${name}-error`}>
              {errorMessage}
            </span>
          )}
        </div>
      )}
    </Row>
  )
}

interface IAvatarFieldProps extends ILayoutSettings {
  avatarUrl: string
  onLoadImage: (image: string) => void
}

const AvatarField: FC<IAvatarFieldProps> = ({ avatarUrl, onLoadImage, isTopAligned, isfullWidth }) => {
  const fileRef = useRef<HTMLInputElement>(null)
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files && event.currentTarget.files[0]
    if (file) {
      const type = file.type?.split("/")
      if (type?.length > 0) {
        const isImage = type[0] === "image"
        if (isImage) {
          const reader = new FileReader()
          reader.onload = () => {
            onLoadImage((reader.result as any) as string)
            if (fileRef.current) {
              fileRef.current.value = "" // reseting the input
            }
          }

          if (file) {
            reader.readAsDataURL(file)
          }
        }
      }
    }
  }

  return (
    <FormGenericRow label="Pool Avatar" isTopAligned={isTopAligned} isfullWidth={isfullWidth}>
      <AvatarFieldWrap>
        <div className="input-section">
          <label className="custom-upload">
            <input id="pool_avatar" type="file" className="input" onChange={onFileChange} accept="image/*" ref={fileRef} />
            <div
              className={cx({
                avatar: true,
                "with-image": !!avatarUrl,
              })}
            >
              {(avatarUrl && <img src={avatarUrl} alt="Avatar" />) || <PhotoSvg />}
              {avatarUrl && (
                <div className="avatar-hover">
                  <PhotoSvg />
                </div>
              )}
            </div>
          </label>
          <span className="description" data-cy="new-pool-avatar-lbl">
            Add a photo to personalize your pool
          </span>
        </div>
      </AvatarFieldWrap>
    </FormGenericRow>
  )
}

interface IOpenInvitesProps extends ILayoutSettings {
  openInvites: boolean
  onClick: (openInvites: boolean) => void
  showNewIcon: boolean
}
const OpenInvitesRow: FC<IOpenInvitesProps> = ({ isfullWidth, isTopAligned, onClick, openInvites, showNewIcon }) => {
  return (
    <FormGenericRow label="Pool Invites" isTopAligned={isTopAligned} isfullWidth={isfullWidth}>
      <OpenInvitesContainer>
        <OpenInvitesButton className={openInvites ? "" : "selected"} onClick={() => onClick(false)} data-cy="managers-only-btn">
          <div className="icon-container">
            <LockSvg data-cy="lock-icon" />
          </div>
          <span className="title" data-cy="managers-only-hdr">
            Managers Only
          </span>
          <span className="subtitle" data-cy="managers-only-lbl">
            Only managers can send pool invites
          </span>
        </OpenInvitesButton>
        <OpenInvitesButton className={openInvites ? "selected" : ""} onClick={() => onClick(true)} data-cy="open-invites-btn">
          {showNewIcon ? <NewIcon color={palette.primaryActive} className="new-icon test" data-cy="new-icon" /> : null}
          <div className="icon-container" data-cy="globe-icon">
            <Globe />
          </div>
          <span className="title" data-cy="open-invites-hdr">
            Open Invites
          </span>
          <span className="subtitle" data-cy="open-invites-lbl">
            All pool participants can send pool invites
          </span>
        </OpenInvitesButton>
      </OpenInvitesContainer>
    </FormGenericRow>
  )
}
interface IFormRadioProps extends ILayoutSettings {
  label: string
}

const FormRadioInput: FC<IFormRadioProps> = ({ label, isfullWidth, children }) => (
  <Row className={cx({ "row--full-width": isfullWidth, "is-radio-group": true })}>
    <label className="row__label">{label}</label>
    <div className="row__radioGroup">{children}</div>
  </Row>
)

interface IFormGenericRow extends ILayoutSettings {
  label: string
  dataCyLabel?: string
}

const FormGenericRow: FC<IFormGenericRow> = ({ label, isTopAligned, isfullWidth, children, dataCyLabel }) => (
  <Row className={cx({ "row--top-aligned": isTopAligned, "row--full-width": isfullWidth })}>
    <label className="row__label" data-cy={dataCyLabel}>
      {label}
    </label>
    <div className="row__generic">{children}</div>
  </Row>
)

interface IFormHelperTextProps extends ILayoutSettings {
  label?: string
  dataCyChildren?: string
}

export const FormHelperText: FC<IFormHelperTextProps> = ({ label, isfullWidth, children, dataCyChildren }) => (
  <Row className={cx({ "row--full-width": isfullWidth })}>
    <div className="row__label">{label}</div>
    <div className="row__textWrapper" data-cy={dataCyChildren}>
      {children}
    </div>
  </Row>
)
export const FormErrorText: FC<ILayoutSettings> = ({ isfullWidth, children }) => (
  <Row className={cx({ "row--full-width": isfullWidth, "is-error-message": true })}>
    <div className="row__textWrapper is-error-message">{children}</div>
  </Row>
)

const RoundBonusTable = ({
  modifiers,
  bonuses,
  mode = "read",
  labels,
  onChangeBonus = () => undefined,
  onChangeModifier = () => undefined,
}: {
  modifiers: RoundModifierTypeEnum[]
  bonuses: number[]
  labels: string[]
  mode: "read" | "edit"
  onChangeModifier?: (index: number, value: RoundModifierTypeEnum) => void
  onChangeBonus?: (index: number, value: number) => void
}) => {
  const showModifiers = modifiers.findIndex((x) => x !== RoundModifierTypeEnum.NONE) >= 0
  if (mode === "read") {
    return (
      <RoundBonusTableWrap>
        <thead>
          <tr>
            <th className="label all-caps">Round</th>
            <th className="all-caps">Points</th>
            {showModifiers && <th className="all-caps">Bonuses</th>}
          </tr>
        </thead>
        <tbody>
          {bonuses.map((value, idx) => {
            const label = labels[idx] || ""
            return (
              <tr key={`${value}-${label}-${idx}`}>
                <td className="label">{label}</td>
                <td>{value}</td>
                {showModifiers && <td>{getModifierLabel(modifiers[idx])}</td>}
              </tr>
            )
          })}
        </tbody>
      </RoundBonusTableWrap>
    )
  }
  return (
    <RoundBonusTableWrap className="mode--edit">
      <thead>
        <tr>
          <th className="label">ROUND</th>
          <th>POINTS</th>
          {bonusOptions.map((option) => (
            <th>{option.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {bonuses.map((value, idx) => {
          const label = labels[idx] || ""
          return (
            <tr key={`${value}-${label}-${idx}`}>
              <td className="label">{label}</td>
              <td className="input-text">
                <input type="number" name="bonus_option" value={value.toFixed(0)} onChange={(e) => onChangeBonus(idx, Number(e.target.value || 0))} />
              </td>
              {bonusOptions.map((option) => (
                <td className="option">
                  <div className="option-content">
                    <RadioButton
                      onChange={() => onChangeModifier(idx, option.value)}
                      label={`bonus_option_${idx}`}
                      radioSize="md"
                      name={`bonus_option_${idx}`}
                      id={`bonus_option_${idx}_${option.value}`}
                      noWrap
                      hideLabel
                      isChecked={modifiers[idx] === option.value}
                    />
                  </div>
                </td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </RoundBonusTableWrap>
  )
}

interface IRulesModalProps {
  formik: IPoolFormRulesFieldsProps["formik"]
  pool: IPoolFormRulesFieldsProps["pool"]
  onSave?: (roundBonuses: Array<number>, roundModifiers: Array<RoundModifierTypeEnum>) => void
  onClose?: () => void
  defaultRoundModifiers: RoundModifierType[] | null
  defaultRoundBonuses: number[] | null
}

const RulesModal: FC<IRulesModalProps> = ({ formik, pool, onClose, onSave, defaultRoundBonuses, defaultRoundModifiers }) => {
  const { values } = formik

  const segment = getSegment(pool)
  const periods = segment.periods.edges.map((e) => e.node).sort(sortByOrder)
  const isBracket = segment.gameType === GameTypeEnum.BRACKET
  const isConfTourneyBracket = isBracket && segment.subsection === ENUM_CONFERENCE_TOURNAMENT
  const labels = isConfTourneyBracket ? tournamentRBLabels : periods.slice(1).map((x) => x.description)

  const roundBonuses = values.poolSettings?.roundBonuses ?? []
  const roundModifiers = values.poolSettings?.roundModifiers ?? []

  const [stateModifiers, setStateModifiers] = React.useState<RoundModifierTypeEnum[]>(() => [...roundModifiers])
  const [stateBonuses, setStateBonuses] = React.useState<number[]>(() => [...roundBonuses])
  const [showScoring, setShowScoring] = React.useState(true)

  const hasDefaultsBonuses = compareArrays(defaultRoundBonuses || [], stateBonuses) && compareArrays(defaultRoundModifiers || [], stateModifiers)
  const hasInitialBonuses = compareArrays(roundBonuses, stateBonuses) && compareArrays(roundModifiers, stateModifiers)

  const onChangeModifier = React.useCallback(
    (index: number, value: RoundModifierTypeEnum) => {
      stateModifiers[index] = value
      setStateModifiers([...stateModifiers])
    },
    [stateModifiers],
  )

  const onChangeBonus = React.useCallback(
    (index: number, value: number) => {
      if (value.toString().length <= 3) {
        if (value < 1) {
          stateBonuses[index] = 1
        } else if (value > 999) {
          stateBonuses[index] = 999
        } else {
          stateBonuses[index] = value
        }
      }
      setStateBonuses([...stateBonuses])
    },
    [stateBonuses],
  )

  const resetConfigs = React.useCallback(() => {
    setStateBonuses([...(defaultRoundBonuses || [])])
    setStateModifiers([...(defaultRoundModifiers as RoundModifierTypeEnum[])])
  }, [defaultRoundBonuses, defaultRoundModifiers])

  const handleOnSave = () => {
    onSave?.(stateBonuses, stateModifiers)
    onClose?.()
  }

  return (
    <ModalWrapper modalType="dialog" variant="white" padded={false}>
      <RulesModalWrap>
        {showScoring && (
          <div className="bonus-modifier">
            <div className="header">Customize Scoring</div>
            <div className="content">
              <RoundBonusTable
                mode="edit"
                onChangeBonus={onChangeBonus}
                onChangeModifier={onChangeModifier}
                bonuses={stateBonuses}
                modifiers={stateModifiers}
                labels={labels}
              />
              <span className="information">
                <span className="description">
                  You can adjust the amount of points each correct pick is worth. Each round is required to have a point value.
                </span>
                <span className="rules-link" role="button" onClick={() => setShowScoring(false)}>
                  See Bonuses
                </span>
              </span>
            </div>
            <div className="footer">
              <div className="left">
                <Button variant="secondary" onClick={resetConfigs} disabled={hasDefaultsBonuses}>
                  Restore Defaults
                </Button>
              </div>
              <div className="right">
                <Button variant="secondary" onClick={onClose}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={handleOnSave} disabled={hasInitialBonuses}>
                  Apply
                </Button>
              </div>
            </div>
          </div>
        )}
        {!showScoring && (
          <div className="bonus-modifier-description">
            <div className="header">
              <BackSvg onClick={() => setShowScoring(true)} /> Bonuses
            </div>
            <span className="subtitle">Bonuses are rewarded based on the team’s seed, the higher the seed the more points earned.</span>
            <div className="rule-description">
              <span className="rule-name">Points + Seed Bonus</span>
              <span className="rule">
                If you correctly pick a #3 seed to win in the second round, you get 2 points (second round)* + 3 points (#3 seed) = 5 total points.
              </span>
            </div>
            <div className="rule-description">
              <span className="rule-name">Points x Seed Bonus</span>
              <span className="rule">
                If you correctly pick a #3 seed to win in the second round, you get 2 points (second round)* x 3 points (#3 seed) = 6 total points.
              </span>
            </div>
            <span className="disclaimer">
              *The point values in the examples shown above are based on the CBS Sports Standard Scoring rules of 2 points per correct pick in the
              second round. Point values can vary depending on customization.
            </span>
          </div>
        )}
      </RulesModalWrap>
    </ModalWrapper>
  )
}

/**
 * Bracket pool combinable forms
 */
type FormVariantType = "default" | "fullWidth"

interface IPoolFormGeneralFieldsProps {
  formik: FormikProps<IUpsertBracketPoolGeneralFields>
  gameInstanceUid: string
  isUpdate?: boolean
  additionalFields?: React.ReactElement
  variant?: FormVariantType
}

export const PoolFormGeneralFields: FC<IPoolFormGeneralFieldsProps> = ({
  formik,
  additionalFields,
  gameInstanceUid,
  variant = "default",
  isUpdate,
}) => {
  const { values, setFieldValue, setErrors, errors, touched } = formik
  const { usesMagicLink, avatarUrl, poolSettings } = values
  const showPasswordBox = !usesMagicLink
  const [fileImage, setFileImage] = useState<string>("")
  const [openInvitesWarningModal, setOpenInvitesWarningModal] = useState(false)
  const isFullWidth = variant === "fullWidth"
  const deviceType = useDeviceType()
  const isMobile = deviceType === "handheld"

  const hideModal = () => {
    setFileImage("")
  }

  const onUploadComplete = useCallback(
    (imageUrl: string) => {
      setFieldValue("avatarUrl", imageUrl)
      hideModal()
    },
    [setFieldValue],
  )

  const onUploadError = useCallback(
    (error: IValidationErrorBody) => {
      setErrors(error)
      hideModal()
    },
    [setErrors],
  )

  const generalError = (errors as any)?.base
  const onOpenInvitesClick = (openInvites: boolean) => {
    if (isUpdate && poolSettings?.openInvites && !openInvites && !openInvitesWarningModal) {
      setOpenInvitesWarningModal(true)
    } else {
      setFieldValue("poolSettings.openInvites", openInvites)
    }
  }
  const handleTurnOffOpenInvites = () => {
    setOpenInvitesWarningModal(false)
    setFieldValue("poolSettings.openInvites", false)
  }
  const cancelTurnOffOpenInvites = () => setOpenInvitesWarningModal(false)

  return (
    <>
      <FormTextInput
        label="Pool Name"
        name="name"
        id="pool-name"
        required
        className={errorClassFor(formik, "name")}
        placeholder="Pool Name"
        showCount
        minLength={constants.POOL_NAME_MIN_LENGTH}
        maxLength={constants.POOL_NAME_MAX_LENGTH}
        helperText={`Must be at least ${constants.POOL_NAME_MIN_LENGTH} characters`}
        isfullWidth={isFullWidth}
        errorMessage={touched.name ? errors?.name : undefined}
      />
      {!isMobile && (
        <FormTextInput
          label="Pool Slogan"
          name="slogan"
          id="pool-slogan"
          maxLength={constants.POOL_SLOGAN_MAX_LENGTH}
          showCount
          className={errorClassFor(formik, "slogan")}
          placeholder="Pool Slogan"
          isfullWidth={isFullWidth}
          errorMessage={touched.slogan ? errors?.slogan : undefined}
        />
      )}
      {constants.ENABLE_OPEN_INVITES && (
        <>
          <OpenInvitesRow
            isfullWidth={isFullWidth}
            onClick={onOpenInvitesClick}
            openInvites={poolSettings?.openInvites || false}
            showNewIcon={(isUpdate && !isMobile) || false}
          />
          <Modal isOpen={openInvitesWarningModal} padded={false}>
            <ConfirmDialog
              title="Disabling Participant Invites?"
              subTitle="This change will invalidate any outstanding invites that have been previously sent by participants."
              cancelText="Cancel"
              confirmText="Continue"
              onCancel={cancelTurnOffOpenInvites}
              onConfirm={handleTurnOffOpenInvites}
            />
          </Modal>
        </>
      )}
      <FormRadioInput label="Pool Security" isfullWidth={isFullWidth} data-cy="pool-invite-labels">
        <CustomRadioButton
          id="secret-link-radio"
          name="usesMagicLink"
          label="Invite by private link"
          radioSize="md"
          isChecked={usesMagicLink}
          onChange={() => {
            setFieldValue("password", "", false)
            setFieldValue("usesMagicLink", true, true)
          }}
        />
        <CustomRadioButton
          id="password-radio"
          name="usesMagicLink"
          radioSize="md"
          label="Require password to join"
          isChecked={!usesMagicLink}
          onChange={() => setFieldValue("usesMagicLink", false)}
        />
      </FormRadioInput>
      {showPasswordBox && (
        <FormTextInput
          label="Pool Password"
          name="password"
          id="pool-password"
          required
          minLength={constants.POOL_PASSWORD_MIN_LENGTH}
          maxLength={999}
          helperText={`Must be at least ${constants.POOL_PASSWORD_MIN_LENGTH} characters`}
          className={errorClassFor(formik, "password")}
          placeholder={"Pool Password"}
          isfullWidth={isFullWidth}
          errorMessage={touched.password ? errors?.password : undefined}
          focusOnMount
        />
      )}
      {!isMobile && <AvatarField avatarUrl={avatarUrl ?? ""} onLoadImage={setFileImage} isfullWidth={isFullWidth} />}
      {generalError && <FormErrorText isfullWidth>{generalError}</FormErrorText>}
      {additionalFields}
      <PoolAvatarForm
        isOpen={!!fileImage}
        file={fileImage}
        onClose={hideModal}
        onComplete={onUploadComplete}
        gameInstanceUid={gameInstanceUid}
        onError={onUploadError}
      />
    </>
  )
}

interface IPoolFormRulesFieldsProps {
  formik: FormikProps<IUpsertBracketPoolRulesFields>
  pool: PoolDetailsQuery_pool
  additionalFields?: React.ReactElement
  variant?: FormVariantType
}

const textAreaStyles: React.CSSProperties = {
  fontSize: pxToRem(14),
  lineHeight: pxToRem(20),
  padding: pxToRem(16),
  resize: "none",
}
export const PoolFormRulesFields: FC<IPoolFormRulesFieldsProps> = ({ formik, pool, additionalFields, variant }) => {
  const { values, setFieldValue } = formik
  const isFullWidth = variant === "fullWidth"

  const { defaultRoundBonuses, defaultRoundModifiers } = useMemo(() => {
    const defaultSettings = getFullPoolSettings(pool, true)
    const defaultRoundBonuses = defaultSettings.roundBonuses ?? []
    const defaultRoundModifiers = defaultSettings.roundModifiers ?? defaultRoundBonuses.map(() => "NONE")
    return {
      defaultRoundBonuses,
      defaultRoundModifiers,
    }
  }, [pool])

  const roundBonuses = values.poolSettings?.roundBonuses ?? []
  const roundModifiers = values.poolSettings?.roundModifiers ?? []
  const maxEntriesPerUser = values.poolSettings?.maxEntriesPerUser ?? 1

  const segment = getSegment(pool)
  const periods = segment.periods.edges.map((e) => e.node).sort(sortByOrder)
  const isBracket = segment.gameType === GameTypeEnum.BRACKET
  const isConfTourneyBracket = isBracket && segment.subsection === ENUM_CONFERENCE_TOURNAMENT
  const labels = isConfTourneyBracket ? tournamentRBLabels : periods.slice(1).map((x) => x.description)

  const [showModal, setShowModal] = React.useState(false)

  const maxEntriesPerUserOptions = useMemo(
    () => new Array(constants.POOL_MAX_ENTRIES_PER_USER).fill(true).map((_, index) => ({ label: (index + 1).toString(), value: index + 1 })),
    [],
  )
  const maxEntriesPerUserValue = useMemo(() => maxEntriesPerUserOptions.find((x) => x.value === maxEntriesPerUser), [
    maxEntriesPerUser,
    maxEntriesPerUserOptions,
  ])

  const onSave = (newBonuses: number[], newModifiers: RoundModifierType[]) => {
    if (values) {
      setFieldValue("poolSettings.roundBonuses", newBonuses)
      setFieldValue("poolSettings.roundModifiers", newModifiers)
    }
  }

  const hasDefaultsBonuses =
    compareArrays(defaultRoundBonuses || [], values.poolSettings?.roundBonuses || []) &&
    compareArrays(defaultRoundModifiers || [], values.poolSettings?.roundModifiers || [])

  const handleMessageBoardCheck = (e: React.ChangeEvent<HTMLInputElement>, field) => {
    const isChecked = e.target.checked
    setFieldValue(field.name, isChecked)
    if (!isChecked) {
      trackActionByKey("disable-message-board")
    }
  }
  return (
    <>
      <FormGenericRow label="Scoring Rules" isTopAligned isfullWidth={isFullWidth} dataCyLabel="scoring-rules-lbl">
        <ScoringTable>
          <span className="scoringTable__title">
            {hasDefaultsBonuses ? "CBS Sports Standard Scoring" : "Custom Scoring"}
            <PencilSvg data-cy="edit-scoring-rules" onClick={() => setShowModal(true)} />
          </span>
          <div className="score-data">
            <RoundBonusTable mode="read" bonuses={roundBonuses} modifiers={roundModifiers} labels={labels} />
          </div>
        </ScoringTable>
      </FormGenericRow>

      <FormGenericRow label="Tiebreaker" isfullWidth={isFullWidth} dataCyLabel="tiebreaker-lbl">
        <Field name="poolSettings.mainTiebreaker">
          {({ field }) => (
            <CustomCheckbox
              label="Championship Game’s Total Score"
              name={field.name}
              size="md"
              isChecked={field.value === TiebreakerTypeEnum.TOTAL_SCORE}
              onChange={(e) => setFieldValue(field.name, (e.target as any).checked ? TiebreakerTypeEnum.TOTAL_SCORE : TiebreakerTypeEnum.NONE)}
            />
          )}
        </Field>
      </FormGenericRow>

      <FormGenericRow label="Message Board" isfullWidth={isFullWidth} dataCyLabel="message-board-lbl">
        <Field name="poolSettings.includeMessageBoard">
          {({ field }) => (
            <CustomCheckbox
              label="Include Message Board"
              hideLabel
              name={field.name}
              size="md"
              isChecked={!!field.value}
              onChange={(e) => handleMessageBoardCheck(e as React.ChangeEvent<HTMLInputElement>, field)}
            />
          )}
        </Field>
      </FormGenericRow>

      <FormGenericRow label="Max Brackets Per Player" isfullWidth={isFullWidth} dataCyLabel="max-brackets-lbl">
        <Field name="poolSettings.maxEntriesPerUser">
          {({ field }) => {
            return (
              <div className="row__selectWrapper">
                <Select
                  options={maxEntriesPerUserOptions}
                  onBlur={field.onBlur}
                  onSelect={(item) => setFieldValue(field.name, item.value)}
                  selectedValue={maxEntriesPerUserValue}
                  data-cy="max-brackets-dropdown"
                />
              </div>
            )
          }}
        </Field>
      </FormGenericRow>

      <FormTextInput
        name="constitution"
        id="constitution"
        label="Additional Rules"
        isMultiline
        rows={6}
        isTopAligned
        isfullWidth={isFullWidth}
        style={textAreaStyles}
        maxLength={constants.POOL_CONSTITUTION_MAX_LENGTH}
        showCount={true}
      />

      {additionalFields}

      <Modal
        modalType="modal"
        padded={false}
        isOpen={showModal}
        afterClose={() => setShowModal(false)}
        variant={"white"}
        onBackgroundClick={() => undefined}
      >
        <RulesModal
          onSave={onSave}
          onClose={() => setShowModal(false)}
          pool={pool}
          formik={formik}
          defaultRoundBonuses={defaultRoundBonuses}
          defaultRoundModifiers={defaultRoundModifiers}
        />
      </Modal>
    </>
  )
}
