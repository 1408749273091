import fetch from "node-fetch"
import { useState, useEffect } from "react"
import constants from "../../common/constants"

const useClientSettings = () => {
  const [settings, seSettings] = useState<Record<string, string> | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data: Record<string, string> = {}
        const useLocalDebug = true
        if (useLocalDebug && constants.IS_LOCAL) {
          data = {
            SHOW_MAIN_BPC_PROMO: "false",
            SHOW_NEURON_PROMO: "true",
            SHOW_MAIN_OPM_PROMO: "false",
            SHOW_NEURON_OPM_PROMO: "true",
            SHOW_GAMBLING_PARTNER_ATTRIBUTION: "true",
            SHOW_WEEKLY_STANDINGS_TABLE_SCROLLBAR: "true",
            SHOW_MAKE_PICKS_BANNER: "true",
            SHOW_PICKS_TOOLBAR: "true",
            SHOW_STANDINGS_BANNER_AD: "true",
            SHOW_MESSAGE_BOARD_EDITIONS: "true",
            SHOW_NEWSLETTER_SUBSCRIPTION_MODAL: "true",
            SHOW_POOL_HOME_NEURON_PROMO: "true",
          }
        } else {
          // This is in the legacy app and caching is handled there
          const fetchData = await fetch("/client-settings")
          data = await fetchData.json()
        }

        seSettings(data)
      } catch (error) {
        console.error("unable to fetch client settings: ", error)
      }
    }

    fetchData()
  }, [])

  return settings
}
export default useClientSettings
