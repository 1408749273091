import { gql } from "@apollo/client"
import { CENTRAL_ENTRY_FRAGMENT, CENTRAL_POOL_DETAILS_FRAGMENT, CENTRAL_SEASON_FRAGMENT, POOL_SETTINGS_FRAGMENT } from "../utils/central-queries"

export const CURRENT_USER_SPOE_QUERY = gql`
  query CurrentUserSpoeQuery {
    userTeams @client {
      id
      userTeams
    }
  }
`

export const GLOBAL_MEDIA_NAV_QUERY = gql`
  query GlobalMediaNavQuery {
    mediaGlobalNav {
      id
      title
      href
      iconString
      isExternal
    }
  }
`
const ENTRY_GLANCE_QUERY_PERIOD_POINT_FRAGMENT = gql`
  fragment EntryGlanceQueryPeriodPointFragment on PeriodPoint {
    id
    periodId
    poolRank
    percentile
    fantasyPoints
    picksCount
    maxPicksCount
  }
`

export const SENT_POOL_INVITATIONS_FRAGMENT = gql`
  fragment PoolInvitationFragment on Entry {
    id
    poolPassword
    sentInvitations(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        totalCount
      }
      edges {
        node {
          id
          foreignId
          status
        }
      }
    }
  }
`

export const POOL_INVITATION_CENTER_QUERY = gql`
  query PoolInvitationCenterQuery($entryId: ID!, $first: Int!, $after: ID) {
    entry(id: $entryId) {
      ...PoolInvitationFragment
      pool {
        id
        name
        url
        poolType
      }
    }
    currentUser {
      id
    }
  }
  ${SENT_POOL_INVITATIONS_FRAGMENT}
`

export const SEND_POOL_INVITATION_MUTATION = gql`
  mutation SendPoolInvitationMutation($to: String!, $subject: String, $message: String, $entryId: ID!, $first: Int!, $after: ID) {
    sendInvitations(to: $to, entryId: $entryId, subject: $subject, message: $message) {
      ...PoolInvitationFragment
    }
  }
  ${SENT_POOL_INVITATIONS_FRAGMENT}
`

export const SEND_POOL_BACKUP_MUTATION = gql`
  mutation sendPoolBackUpEmailsMutation($poolId: ID!, $subject: String, $message: String, $isPoolMail: Boolean) {
    sendPoolBackUpEmails(poolId: $poolId, subject: $subject, message: $message, isPoolMail: $isPoolMail)
  }
`

export const SEND_PICKS_REMINDER_NOTIFICATION_MUTATION = gql`
  mutation sendPicksReminderNotificationMutation($poolId: ID!, $subject: String, $message: String) {
    sendPicksReminderNotification(poolId: $poolId, subject: $subject, message: $message)
  }
`

export const POOL_SEASON_STANDINGS_QUERY = gql`
  query PoolSeasonStandingsQuery(
    $gameInstanceUid: String!
    $poolId: ID!
    $first: Int!
    $includedEntryIds: [ID!]
    $orderBy: EntryOrderByType
    $sortingOrder: SortingOrderByType
    $after: ID
    $skipAncestorPools: Boolean = false
    $skipPeriodPoints: Boolean = false
    $skipCheckForIncompleteEntries: Boolean = true
  ) {
    gameInstance(game: { uid: $gameInstanceUid }) {
      id
      pool(id: $poolId) {
        id
        name
        url
        entriesCount
        entriesWithPicksCount
        hasIncompleteEntries @skip(if: $skipCheckForIncompleteEntries)
        entries(first: $first, after: $after, includedEntryIds: $includedEntryIds, orderBy: $orderBy, sortingOrder: $sortingOrder) {
          pageInfo {
            hasNextPage
            startCursor
            endCursor
          }
          edges {
            cursor
            node {
              id
              name
              fantasyPoints
              poolRank
              url
              hasGameDataOnly
              championTeam {
                id
                cbsTeamId
                sportType
                abbrev
              }
              tiebreakerAnswers {
                id
                tiebreakerQuestionId
                value
              }
              picksCount
              maxPicksCount
              needsToMakePicks
              maxPoints
              correctPicks
              createdAt
              isMine
              periodPoints(first: 50) @skip(if: $skipPeriodPoints) {
                edges {
                  node {
                    id
                    fantasyPoints
                    periodId
                    poolRank
                  }
                }
              }
            }
          }
        }
        poolEditions @skip(if: $skipAncestorPools) {
          id
          poolId
          seasonYear
        }
        season {
          id
          season
          year
          segments(first: 1) {
            edges {
              node {
                id
                periods(first: 50) {
                  edges {
                    node {
                      id
                      description
                      order
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const RESET_USER_MUTATION = gql`
  mutation ResetUserMutation {
    resetUser(id: "current")
  }
`

export const POOL_PERIOD_QUERY = gql`
query PoolPeriodQuery(
  $gameInstanceUid: String!,
  $order: Int,
  $year: Int,
  $season: SeasonEnumType,
  $periodId: ID,
  $poolId: ID,
) {
  gameInstance(game: {uid: $gameInstanceUid}) {
    id
    period(period: { order: $order, year: $year, season: $season, periodId: $periodId, poolId: $poolId }) {
      id
      description
      order
      isCurrent
      isPickable
      isInComingSoon
      notificationBannerHtml
      notificationBannerBackgroundColor
      notificationBannerColor
      tournamentId
      tournamentRound
      locksAt
      startsAt
      tiebreakerQuestions {
        id
        label
        key
        options {
          id
          value
          label
        }
      }
      tiebreakerQuestionTitle
      segment {
        id
        gameType
        sportType
        subsection
        tournaments {
          id
          cbsId
          name
          abbrev
        }
        periods(first: 50) {
          edges {
            node {
              id
              order
              description
              tournamentId
              tournamentRound
              locksAt
              isInComingSoon
            }
          }
        }
        season {
          ...CentralSeasonFragment
        }
      }
      matchups{
        id
        tournamentId
        tournamentDescription
        tournamentRound
        roundOrdinal
        topItemSeed
        bottomItemSeed
        topItemId
        bottomItemId
        winnerPlaysIntoOrdinal
        winnerPlaysIntoPosition
        winnerId
        groupPosition
        groupName
        tournamentRoundName
        isPlayin
        slotIds
        advancementId
        extra {
          id
          topItemPercentOwned
          bottomItemPercentOwned
        }
        event {
          id
          startsAt
          markedFinalAt
          homeTeamSpread(input: { poolId: $poolId })
          winningTeamId
          homeTeamScore
          awayTeamScore
          gameStatusDesc
          timeRemaining
          gamePeriod
          tvInfoName
          possession
          homeTeamId
          awayTeamId
        }
      }
    }
  }
  ${CENTRAL_SEASON_FRAGMENT}
}
`

export const ENTRY_DETAILS_FRAGMENT = gql`
  fragment EntryDetailsFragment on Entry {
    id
    name
    gameInstanceUid
    isMine
    url
    avatarUrl
    hideEmail
    roles
    memberId
    hidePicksOnboarding
    hasSeenNativeAppPromo
    totalPicksCount
    maxPicksCount
    poolRank
    correctPicks
    fantasyPoints
    maxPoints
    noMessageBoardNotification
    reinvitedPassword
    currentSeasonEntryId
    hasAutogeneratedName
    lastDismissedReactivateSeasonId
    canDeleteEntry
    canLeavePool
    userId
    isOfflineEntry
    userInfo {
      id
      email
    }
    championTeam {
      id
      cbsTeamId
    }
    pool @skip(if: $skipPoolData) {
      id
      name
      url
      season {
        id
        challengePoolId
      }
    }
    needsToMakePicks(period: { periodId: $periodId })
    periodPoint(period: { periodId: $periodId }, excludeUnranked: false) {
      ...EntryGlanceQueryPeriodPointFragment
    }
    picks(period: { periodId: $periodId }) {
      id
      slotId
      itemId
      periodId
      additionalPoints
      spreadForItem
    }
    tiebreakerAnswers(period: { periodId: $periodId }) {
      id
      tiebreakerQuestionId
      value
    }
  }
  ${ENTRY_GLANCE_QUERY_PERIOD_POINT_FRAGMENT}
`

export const UPSERT_OFFLINE_ENTRY_MUTATION = gql`
  mutation UpsertOfflineEntry($poolId: ID!, $name: String!) {
    upsertOfflineEntry(poolId: $poolId, name: $name) {
      entry {
        ...CentralEntryFragment
      }
    }
  }
  ${CENTRAL_ENTRY_FRAGMENT}
`

export const UPSERT_ENTRY_MUTATION = gql`
mutation UpsertEntryMutation(
  $periodId: ID,
  $picks: [PickInput!],
  $gameInstanceUid: String,
  $entryId: ID,
  $poolId: ID,
  $name: String,
  $forUserId: ID,
  $password: String
  $avatarUrl: String,
  $hideEmail: Boolean,
  $hasSeenNativeAppPromo: Boolean,
  $tiebreakerAnswers: [TiebreakerAnswerInputType!],
  $invitationId: ID,
  $invitedByEntryId: ID,
  $lastDismissedReactivateSeasonId: ID,
  $entryIdToCopy: ID,
  $autoPickProgrammaticOption: ID,
  $sponsorData: SponsorDataInputType
  $noMessageBoardNotification: Boolean,
  $skipPoolData: Boolean = false,
) {
  upsertEntry(
    gameInstanceUid: $gameInstanceUid,
    poolId: $poolId,
    entryId: $entryId,
    periodId: $periodId,
    picks: $picks,
    name: $name,
    entryIdToCopy: $entryIdToCopy,
    password: $password,
    avatarUrl: $avatarUrl,
    hideEmail: $hideEmail,
    hasSeenNativeAppPromo: $hasSeenNativeAppPromo,
    tiebreakerAnswers: $tiebreakerAnswers,
    lastDismissedReactivateSeasonId: $lastDismissedReactivateSeasonId,
    invitationId: $invitationId,
    invitedByEntryId: $invitedByEntryId,
    autoPickProgrammaticOption: $autoPickProgrammaticOption,
    sponsorData: $sponsorData
    forUserId: $forUserId,
    noMessageBoardNotification: $noMessageBoardNotification,
  ) {
    entry {
      ...EntryDetailsFragment
    }
  }
  ${ENTRY_DETAILS_FRAGMENT}
}
`

export const ADMIN_ADD_MEMBER_MUTATION = gql`
  mutation AdminAddMemberMutation($poolId: ID!, $userLogin: String) {
    adminAddMember(poolId: $poolId, userLogin: $userLogin) {
      entry {
        id
        name
      }
    }
  }
`

export const ENTRY_DETAILS_QUERY = gql`
  query EntryDetailsQuery($entryId: ID!, $periodId: ID!, $skipPoolData: Boolean = false) {
    entry(id: $entryId) {
      ...EntryDetailsFragment
    }
  }
  ${ENTRY_DETAILS_FRAGMENT}
`

export const POOL_DETAILS_FRAGMENT = gql`
  fragment PoolDetailsFragment on Pool {
    constitution
    ...CentralPoolDetailsFragment
    season {
      segments(first: 10) {
        edges {
          cursor
          node {
            id
            poolSettings {
              ...PoolSettingsFragment
            }
            gameType
            sportType
            subsection
            periods(first: 50) {
              edges {
                cursor
                node {
                  id
                  order
                  isCurrent
                  description
                  maxPicksCount
                }
              }
            }
          }
        }
      }
    }
  }
  ${CENTRAL_POOL_DETAILS_FRAGMENT}
  ${POOL_SETTINGS_FRAGMENT}
`
export const POOL_USERS_ENTRIES_QUERY = gql`
  query PoolUsersEntriesQuery($poolId: ID!, $forUserId: ID!, $skipSpoeStatusCheck: Boolean = false) {
    pool(id: $poolId) {
      id
      entries(first: 50, forUserId: $forUserId, skipSpoeStatusCheck: $skipSpoeStatusCheck) {
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            id
            ...CentralEntryFragment
          }
        }
      }
    }
  }
  ${CENTRAL_ENTRY_FRAGMENT}
`
export const POOL_DETAILS_QUERY = gql`
  query PoolDetailsQuery($poolId: ID!) {
    pool(id: $poolId) {
      ...PoolDetailsFragment
    }
  }
  ${POOL_DETAILS_FRAGMENT}
`
export const POOL_HELP_URL_QUERY = gql`
  query PoolHelpUrlQuery($poolId: ID!, $entryId: ID) {
    pool(id: $poolId) {
      id
      helpDeskUrl(entryId: $entryId)
    }
  }
`

export const POOL_BUG_URL_QUERY = gql`
  query PoolBugUrlQuery($poolId: ID!, $entryId: ID) {
    pool(id: $poolId) {
      id
      bugLinkUrl(entryId: $entryId)
    }
  }
`

export const UPSERT_POOL_MUTATION = gql`
mutation UpsertPoolMutation(
  $seasonId: ID!,
  $poolId: ID,
  $name: String,
  $password: String,
  $usesMagicLink: Boolean,
  $poolSettings: JSON,
  $constitution: String
  $reactivateForSeasonId: ID,
  $slogan: String,
  $avatarUrl: String,
) {
  upsertPool(
    seasonId: $seasonId,
    poolId: $poolId,
    name: $name,
    usesMagicLink: $usesMagicLink,
    password: $password,
    poolSettings: $poolSettings,
    constitution: $constitution,
    reactivateForSeasonId: $reactivateForSeasonId,
    slogan: $slogan,
    avatarUrl: $avatarUrl,
  ) {
    pool {
      ...PoolDetailsFragment
    }
  }
  ${POOL_DETAILS_FRAGMENT}
}
`

export const SET_ENTRY_ROLES_MUTATION = gql`
  mutation SetEntryRolesMutation($memberId: ID!, $roles: [PoolRole!]!) {
    setRoles(memberId: $memberId, roles: $roles) {
      id
      email
      hideEmail
      isMine
      roles
      userInfo {
        id
        firstName
        lastName
        preferredEntryName
      }
    }
  }
`
export const REMOVE_ENTRY_MUTATION = gql`
  mutation RemoveEntryMutation($entryId: ID!) {
    removeEntry(entryId: $entryId) {
      id
    }
  }
`

export const REMOVE_ENTRY_FROM_POOL_MUTATION = gql`
  mutation RemoveEntryFromPoolMutation($entryId: ID!) {
    removeEntry(entryId: $entryId) {
      id
      name
    }
  }
`
export const REMOVE_ENTRIES_MUTATION = gql`
  mutation RemoveEntriesMutation($entryIds: [ID!]!) {
    removeEntries(entryIds: $entryIds)
  }
`

export const LEAVE_POOLS_MUTATION = gql`
  mutation LeavePools($poolIds: [ID!]!, $userId: ID!) {
    leavePools(poolIds: $poolIds, userId: $userId)
  }
`

export const MESSAGE_FRAGMENT = gql`
  fragment MessageFragment on Message {
    id
    from {
      id
      avatarUrl
      userId
      canPostMessages
      userInfo {
        id
        firstName
        lastName
      }
    }
    body
    subject
    sentAt
    replyCount
    rootMessageId
    lastReply {
      id
      sentAt
    }
    deleteInformation {
      id
      deletedBy {
        id
        email
        preferredEntryName
        isCbsAdmin
      }
    }
  }
`

export const POOL_MESSAGE_BOARD_QUERY = gql`
  query PoolMessageBoardQuery($poolId: ID!, $first: Int!, $rootMessageId: ID, $skipRootMessage: Boolean!, $after: ID) {
    message(id: $rootMessageId) @skip(if: $skipRootMessage) {
      ...MessageFragment
    }
    pool(id: $poolId) {
      id
      messages(first: $first, rootMessageId: $rootMessageId, after: $after) {
        pageInfo {
          hasNextPage
          startCursor
          endCursor
          totalCount
        }
        edges {
          node {
            ...MessageFragment
          }
        }
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`
export const UPSERT_POOL_MESSAGE_MUTATION = gql`
  mutation UpsertPoolMessageMutation(
    $poolId: ID!
    $rootMessageId: ID
    $body: String!
    $htmlBody: String!
    $subject: String
    $messageId: ID
    $first: Int
    $after: ID
  ) {
    upsertMessage(
      poolId: $poolId
      rootMessageId: $rootMessageId
      body: $body
      htmlBody: $htmlBody
      subject: $subject
      id: $messageId
      first: $first
      after: $after
    ) {
      ...MessageFragment
    }
  }
  ${MESSAGE_FRAGMENT}
`

export const GET_ENTRY_AVATAR_SIGNED_URL_MUTATION = gql`
  mutation GetEntryAvatarSignedUrlMutation($entryId: ID!, $fileName: String!, $fileType: String!) {
    getEntryAvatarSignedUrl(entryId: $entryId, fileName: $fileName, fileType: $fileType) {
      url
      signedRequest
    }
  }
`
export const UPLOAD_POOL_AVATAR_SIGNED_URL_MUTATION = gql`
  mutation UploadPoolAvatarSignedUrlMutation($poolId: ID, $gameInstanceUid: String, $fileName: String!, $fileType: String!) {
    uploadPoolAvatarSignedUrl(poolId: $poolId, gameInstanceUid: $gameInstanceUid, fileName: $fileName, fileType: $fileType) {
      url
      signedRequest
    }
  }
`

export const FANTASY_ARTICLES_QUERY = gql`
  query FantasyArticlesQuery($limit: Int, $sportType: GameSportTypeEnumType!, $searchSlug: String!) {
    fantasyArticles(limit: $limit, sportType: $sportType, searchSlug: $searchSlug)
  }
`

export const TOGGLE_SILENCE_USER_FOR_POSTING = gql`
  mutation ToggleSilenceUserForPostingMutation($userId: ID!, $poolId: ID!) {
    toggleSilenceUserForPosting(userId: $userId, poolId: $poolId) {
      id
      canPostMessages
    }
  }
`

export const REMOVE_POOL_MESSAGES = gql`
  mutation RemoveMessagesMutation($messageIds: [ID!]!, $reason: DeleteReason!, $notes: String, $poolId: ID!) {
    removeMessages(messageIds: $messageIds, reason: $reason, notes: $notes, poolId: $poolId) {
      ...MessageFragment
    }
  }
  ${MESSAGE_FRAGMENT}
`

export const MA_TEAM_INFO_FRAGMENT = gql`
  fragment MaTeamInfo on MatchupAnalysisTeamAnalysis {
    id
    userPickPercent
    sportslineTeamAnalysis {
      allGamesRecord {
        spread
      }
    }
    rank
    teamBlurb(format: TEXT)
    team {
      id
      cbsTeamId
      abbrev
      wins
      losses
      ties
      mediumName
      sportType
      colorHexDex
      conferenceName
      conferenceAbbrev
      shortName
    }
    teamComparison {
      id
      label
      key
      formattedValue
      rank
      isInverted
    }
    previousGames(limit: 10) {
      id
      record
      homeRecord
      awayRecord
      neutralRecord
      games {
        homeTeam {
          id
          cbsTeamId
          mediumName
          abbrev
          sportType
        }
        awayTeam {
          id
          cbsTeamId
          mediumName
          abbrev
          sportType
        }
        homeTeamScore
        awayTeamScore
        gamePeriod
      }
    }
    situationalStatistics {
      id
      label
      key
      formattedValue
    }
    hasStats
    teamStatistics {
      id
      label
      key
      formattedValue
      normalizedValue
      isInverted
      rank
    }
    keyPlayers(limit: 1) {
      id
      name
      positionAbbrev
      keyStats {
        id
        label
        formattedValue
      }
    }
    injuredPlayers(limit: 5) {
      id
      name
      imgUrl
      positionAbbrev
      estReturn
      injuryStatus
      injuryType
      keyStats {
        id
        label
        formattedValue
      }
    }
  }
`

export const MATCHUP_ANALYSIS_QUERY = gql`
  query MatchupAnalysisQuery(
    $homeTeamAbbrev: String!
    $awayTeamAbbrev: String!
    $sportType: GameSportTypeEnumType!
    $poolId: ID
    $weekNumber: Int
    $gameInstanceUid: String
  ) {
    matchupAnalysis(
      homeTeamAbbrev: $homeTeamAbbrev
      awayTeamAbbrev: $awayTeamAbbrev
      sportType: $sportType
      poolId: $poolId
      weekNumber: $weekNumber
      gameInstanceUid: $gameInstanceUid
    ) {
      id
      homeTeamAnalysis: teamAnalysis(teamAbbrev: $homeTeamAbbrev, opponentAbbrev: $awayTeamAbbrev, sportType: $sportType) {
        ...MaTeamInfo
      }
      awayTeamAnalysis: teamAnalysis(teamAbbrev: $awayTeamAbbrev, opponentAbbrev: $homeTeamAbbrev, sportType: $sportType) {
        ...MaTeamInfo
      }
      teamComparisonOrder
      teamStatsOrder
      sportslinePrediction {
        id
        isSubscriber
        hasGameAnalysis
        matchupNote(format: TEXT)
        upsellCopy(format: HTML)
        pickedTeamAbbrev
        confidencePercent
        projectedHomeTeamScore
        projectedAwayTeamScore
        spreadCoverAnalysis
        subUrl
      }
      expertPicks {
        gameData {
          againstTheSpread {
            expertUserName
            expertId
            pick
            pickSide
          }
          straightUp {
            expertUserName
            expertId
            pick
            pickSide
          }
          line
        }
        expertsData {
          expertId
          userName
          name
          byLine
          imageUrl
          recordData {
            weekRecord {
              straightUp {
                correct
                incorrect
                push
                total
                percentage
              }
              againstTheSpread {
                correct
                incorrect
                push
                total
                percentage
              }
            }
            seasonRecord {
              straightUp {
                correct
                incorrect
                push
                total
                percentage
              }
              againstTheSpread {
                correct
                incorrect
                push
                total
                percentage
              }
            }
          }
        }
      }
      event {
        id
        weather {
          id
          description
          icon
          formattedFahrenheitTemperature
          temperature
          isMetric
        }
        venueInformation {
          id
          stadiumName
          stadiumCity
          stadiumState
        }
        oddsMarket {
          id
          bookUsed {
            id
            name
          }
          moneyLines {
            id
            teamId
            odds
          }
          totals {
            id
            choice
            odds
            # openingTotal # currently crapping out request
            total
          }
          spreads {
            id
            teamId
            odds
          }
        }
      }
    }
  }
  ${MA_TEAM_INFO_FRAGMENT}
`
export const BRACKET_MANAGE_PLAYERS_QUERY = gql`
  query BracketManagePlayersQuery($poolId: ID!, $first: Int!, $after: ID, $searchTerm: String) {
    pool(id: $poolId) {
      id
      players(first: $first, after: $after, searchTerm: $searchTerm) {
        pageInfo {
          hasNextPage
          startCursor
          endCursor
          totalCount
        }
        edges {
          node {
            userId
            poolId
            id
            email
            hideEmail
            isMine
            roles
            isOfflineEntry
            canAddEntry
            canPostMessages
            hasMadeAPick
            userInfo {
              id
              firstName
              lastName
              preferredEntryName
            }
          }
        }
      }
    }
  }
`
export const DECLINE_POOL_INVITATION_MUTATION = gql`
  mutation DeclinePoolInvitationMutation($invitationId: ID!) {
    declineInvitation(invitationId: $invitationId)
  }
`
export const MARK_WELCOME_BACK_MODAL_AS_VIEWED_MUTATION = gql`
  mutation markWelcomeBackModalAsViewedMutation($poolId: ID!) {
    markWelcomeBackModalAsViewed(poolId: $poolId) {
      id
      name
      hasSeenManagerWelcomeBackModal
    }
  }
`

export const AUTO_PICK_QUERY = gql`
  query AutoPickQuery($autopickOption: String!, $poolId: ID, $gameInstanceUid: String) {
    autopick(autopickOption: $autopickOption, poolId: $poolId, gameInstanceUid: $gameInstanceUid) {
      id
      cbsTeamId
      tournamentRound
      roundOrdinal
      itemId
    }
  }
`

export const AUTO_PICK_SOURCES_QUERY = gql`
  query AutoPickSourcesQuery($poolId: ID, $gameInstanceUid: String) {
    autopickSources(poolId: $poolId, gameInstanceUid: $gameInstanceUid) {
      id
      sections: sources {
        id
        description
        order
        isExpert
        isSponsor
        isSportLine
        picks: options {
          id
          name
          description
          disabled
          sponsorId
        }
      }
      sportslineData: sportlineMembership {
        id
        isSubscriber
        subscribeUrl
      }
    }
  }
`
export const ALL_POOL_ENTRIES = gql`
  query allPoolEntriesQuery($poolId: ID!, $limit: Int!) {
    pool(id: $poolId) {
      entries(first: $limit, orderBy: USER_ENTRIES_FIRST) {
        edges {
          node {
            name
            id
            url
            isMine
          }
        }
      }
    }
  }
`
export const POOL_ENTRIES_SEARCH = gql`
  query PoolEntriesSearch($poolId: ID!, $limit: Int!, $searchTerm: String) {
    pool(id: $poolId) {
      entrySearch(first: $limit, searchTerm: $searchTerm) {
        edges {
          node {
            name
            id
            avatarUrl
            url
            isMine
            championTeam {
              id
              cbsTeamId
              sportType
              abbrev
            }
          }
        }
      }
    }
  }
`

export const NEWSLETTER_SUBSCRIPTIONS_QUERY = gql`
  query NewsletterSubscriptions($sportType: String!, $sportYear: Int, $gameInstanceUid: String, $exactMatch: Boolean, $category: String) {
    newsletterSubscriptions(sportType: $sportType, gameInstanceUid: $gameInstanceUid, exactMatch: $exactMatch) {
      id
      sportType
      gameInstanceUid
      title
      options {
        id
        text
        mCode
      }
    }
    newsletter247Sites(categoryString: $category) {
      id
      siteKey
      name
      url
    }
    currentUser {
      id
      hasSeenNewsletterSubscriptionModal(sportType: $sportType, sportYear: $sportYear)
    }
  }
`

export const UPDATE_NEWSLETTER_SUBSCRIPTION_STATUS_MUTATION = gql`
  mutation UpdateNewsletterSubscriptionStatus($sportType: String!, $sportYear: Int!) {
    updateNewsletterSubscriptionStatus(sportType: $sportType, sportYear: $sportYear) {
      id
      hasSeenNewsletterSubscriptionModal(sportType: $sportType, sportYear: $sportYear)
    }
  }
`

export const UPDATE_USER_NEWSLETTER_SUBSCRIPTIONS = gql`
  mutation UpdateUserNewsletterSubscriptions($mapping: JSON, $source: String, $mpid: Int, $siteKey: Int) {
    updateNewsletterSubscriptions(mapping: $mapping, source: $source, mpid: $mpid) {
      id
    }
    createNewsletterSiteSubscription(siteKey: $siteKey) {
      id
    }
  }
`
export const UPDATE_NEW_SETTING_POPOVER_VIEWED_STATUS_MUTATION = gql`
  mutation UpdateNewSettingPopoverViewedStatus($poolId: ID!) {
    updateNewSettingPopoverViewedStatus(poolId: $poolId) {
      id
      hasSeenNewSettingPopover
    }
  }
`
export const PLAYER_BRACKETS_QUERY = gql`
  query PlayerBracketsQuery($poolId: ID!, $userId: ID!, $limit: Int = 20) {
    pool(id: $poolId) {
      id
      entries(first: $limit, forUserId: $userId) {
        edges {
          node {
            id
            name
            gameInstanceUid
            isMine
            url
            avatarUrl
            championTeam {
              id
              cbsTeamId
              sportType
              abbrev
            }
            userId
            pool {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const CURRENT_MEMBERSHIP_QUERY = gql`
  query CurrentMembershipQuery($poolId: ID!) {
    member: memberByPool(poolId: $poolId) {
      id
      email
      hideEmail
      avatarUrl
      userId
      poolId
      isMine
      isOfflineEntry
      roles
      canAddEntry
      canPostMessages
      isManagerModeActive
      reinvitedPassword
      canLeavePool
      noMessageBoardNotification
      userInfo {
        id
        email
      }
      pool {
        id
        name
        url
        season {
          id
        }
      }
    }
  }
`

export const UNREGISTERED_MEMBERS_COUNT = gql`
  query UnregisteredMembersCount($poolId: ID!) {
    pool(id: $poolId) {
      unregisteredMembersCount
    }
  }
`
