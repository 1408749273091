import { ApolloClient, InMemoryCache } from "@apollo/client"
import fetch from "cross-fetch"
import { get as getCookie, set as setCookie } from "es-cookie"
import { Request, Response } from "express"
import { gql } from "@apollo/client"
import constants from "../../common/constants"
import { localStateCookieName } from "../../common/cookies"
import { breakpoints, TDeviceType } from "../utils/style-utils"
import typeDefs from "./local-state-schema.graphql"
import { getDeviceTypeFromUserAgent } from "../../common/common-utils-helpers"
// import { convertSportArenaToApiSportArena } from "../../common/url-utils"

// import { AsyncStorage } from 'react-native'
/* tslint:disable-next-line */
// const localStateCookieName = "_sh-local-state";

const fantasyEnvIsProd = constants.APP_ENV === "prod"

const cookieOptions = { path: "/", expires: 365 * 10 }

export interface LocalState {
  id: string
  __typename: string
  pathname: string
  memory: string
  persisted: string
}
export interface EnflatedLocalState {
  pathname: string
  memory: any
  persisted: any
  deviceType: TDeviceType
  screenW: number
}
export type StorageTypes = "memory" | "persisted" | "historyState" | "hashParam" | "queryParam"

export const GET_LOCAL_STATE_QUERY = gql`
  query GetLocalState {
    currentLocalState @client {
      id
      pathname
      memory
      persisted
    }
  }
`

export const UPDATE_LOCAL_STATE_MUTATION = gql`
  mutation UpdateLocalState($mergeItems: JSON!, $currentPathname: String!) {
    updateLocalState(mergeItems: $mergeItems, currentPathname: $currentPathname) @client {
      id
      pathname
      memory
      persisted
    }
  }
`

const lastKnownState = {
  id: "LocalState:current",
  __typename: "LocalState",
  pathname: "",
  memory: "{}",
  persisted: "{}",
} as LocalState
let isInitalMeasure = true

export const hydrate = (req?: Request, res?: Response, isResizeEvent?: boolean) => {
  const cookie = req ? req.cookies[localStateCookieName] : getCookie(localStateCookieName)
  // console.log(`hydrating: '${cookie}'`);
  const defaults = {
    currentLocalState: lastKnownState,
  }
  try {
    const enflated = JSON.parse(cookie || "{}") as any
    let changed = false
    if (res && req) {
      const userAgent = req.headers["user-agent"] || "unknown"
      if (!enflated.deviceType) {
        // console.warn(`NONO`);
        enflated.deviceType = getDeviceTypeFromUserAgent(userAgent)
        enflated.screenW = breakpoints[enflated.deviceType]
        changed = true
      }
    } else if (isInitalMeasure || isResizeEvent) {
      isInitalMeasure = false
      const updated = getDevicePersistedParamsFromDOM()
      if (updated) {
        enflated.screenW = updated.screenW
        enflated.deviceType = updated.deviceType
        changed = true
      }
    }
    const deflated = JSON.stringify(enflated)
    defaults.currentLocalState.persisted = deflated
    if (changed) {
      if (res) {
        res.cookie(localStateCookieName, deflated, { maxAge: cookieOptions.expires * 1000 * 60 * 60, path: cookieOptions.path })
      } else {
        setCookie(localStateCookieName, deflated, cookieOptions)
      }
    }
  } catch (err) {
    console.error(err)
  }
  return defaults
}

const getDevicePersistedParamsFromDOM = () => {
  if (typeof window !== "undefined") {
    const deviceType =
      (!window.matchMedia(`(min-width: ${breakpoints.handheld}px)`).matches && "handheld") ||
      (!window.matchMedia(`(min-width: ${breakpoints.tablet}px)`).matches && "tablet") ||
      "desktop"
    const screenW = (window.document.documentElement && window.document.documentElement.clientWidth) || window.innerWidth
    return {
      deviceType,
      screenW,
    }
  }
  return null
}

interface LocalStateQueryData {
  currentLocalState: LocalState
}
interface LocalStateQuery {
  data?: LocalStateQueryData
}

export const getStateFromQuery = (query: LocalStateQuery) => {
  if (query.data && query.data.currentLocalState) {
    return enflateState(query.data.currentLocalState)
  } else {
    return enflateState(lastKnownState)
  }
}

export const enflateState = (state: LocalState) => {
  // console.debug('enflateState start')
  // console.dir(state)
  // TODO qac: this runs a TON, can be optimized for sure
  const enflatedState = {
    pathname: state.pathname,
    memory: {},
    persisted: {},
    deviceType: "desktop",
    screenW: breakpoints.lg,
  } as EnflatedLocalState
  try {
    enflatedState.memory = JSON.parse(state.memory)
  } catch (err) {
    console.error(err)
  }
  try {
    const persisted = JSON.parse(state.persisted)
    // const updatedDeflated = updatePersistedIfNeeded(persisted);
    updatePersistedIfNeeded(persisted)
    // NOTE qac: we cannot modify the state directly, since it is reed only + frozen
    // if (updatedDeflated) {
    //   state.persisted = updatedDeflated;
    // }
    enflatedState.deviceType = persisted.deviceType
    enflatedState.screenW = persisted.screenW
    enflatedState.persisted = persisted
  } catch (err) {
    console.error(err)
  }
  // console.debug('enflateState finish')
  return enflatedState
}

const clearNulls = (obj) => {
  let changed = false as boolean
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined || obj[key] === null) {
      delete obj[key]
      changed = true
    }
  })
  return changed
}

const updatePersistedIfNeeded = (enflatedPersisted: any) => {
  const updated = getDevicePersistedParamsFromDOM()
  if (updated && (enflatedPersisted.screenW !== updated.screenW || enflatedPersisted.deviceType !== updated.deviceType)) {
    // console.log(`updating persisted! ${enflatedPersisted.screenW} --> ${updated.screenW} ${enflatedPersisted.deviceType} --> ${updated.deviceType}`);
    enflatedPersisted.screenW = updated.screenW
    enflatedPersisted.deviceType = updated.deviceType
    const updatedDeflated = JSON.stringify(enflatedPersisted)
    setCookie(localStateCookieName, updatedDeflated, cookieOptions)
    lastKnownState.persisted = updatedDeflated
    return updatedDeflated
  }
  return false
}

function logError(err: any) {
  if (typeof window !== "undefined") {
    window.SH_ERROR_NOTIFY(err)
  }
}

async function fantasyContacts(_, arg2) {
  const sport = `football` //convertSportArenaToApiSportArena(arg2.sport || "football")
  return {
    id: `sport-${sport}`,
    __typename: "FantasyContacts",
    by_league_owners: {},
    all_contacts: null,
  }
}

async function userTeams() {
  try {
    // NOTE qac: look up data-layer-sapi.ts for params info
    const parsed = await getFantasyApiQ(`/users/teams?response_format=json&version=3.0&sport=&show_promos=1&include_sportshub=1&target=spoe`)
    // return parsed.body.fantasy_teams
    // TODO qac: we should base this off of current user id
    return {
      id: "current",
      __typename: "UserTeams",
      userTeams: parsed.body.fantasy_teams,
    }
  } catch (err) {
    logError(err)
    // return [] as never
    return {
      id: "current",
      __typename: "UserTeams",
      userTeams: [] as never,
    }
  }
}

async function getFantasyApiQ(path: string) {
  const url = `https://api${fantasyEnvIsProd ? "" : ".qa"}.cbssports.com/fantasy${path}`
  // console.log(`fetching ${url}`)
  const resp = await fetch(url, {
    credentials: "include",
    method: "GET",
    // mode: "cors",
    headers: {
      // NOTE qac: I'm pretty sure even though the cors headers say content-type is accepted, this breaks if included...
      // "Content-Type": "application/json",
      accept: "application/json",
    },
  })
  return resp.json()
}

const resolvers = {
  Mutation: {
    updateLocalState: (_, variables: any, { cache, client }) => {
      const { mergeItems, currentPathname } = variables
      // NOTE qac: since the query is frozen, we must dup it
      const currentState = { ...cache.readQuery({ query: GET_LOCAL_STATE_QUERY }).currentLocalState } as LocalState
      // console.debug(`updateLocalState`)
      // console.dir(currentState)
      // console.dir(variables)
      const enflatedState = enflateState(currentState)
      let newMemory = currentState.memory
      let newPersisted = currentState.persisted
      if (enflatedState.pathname !== currentPathname) {
        // console.debug(`cleaning up old path's memory items`)
        enflatedState.memory = {}
        newMemory = "{}"
        currentState.pathname = currentPathname
      }
      if (mergeItems.persisted) {
        Object.assign(enflatedState.persisted, mergeItems.persisted)
        newPersisted = JSON.stringify(enflatedState.persisted)
      }
      if (mergeItems.memory) {
        Object.assign(enflatedState.memory, mergeItems.memory)
        // console.debug(`Updated currentState:`, enflatedState)
        newMemory = JSON.stringify(enflatedState.memory)
      }
      // clear nulls (mainly for persisted)
      if (clearNulls(enflatedState.memory)) {
        newMemory = JSON.stringify(enflatedState.memory)
      }
      if (clearNulls(enflatedState.persisted)) {
        newPersisted = JSON.stringify(enflatedState.persisted)
      }
      if (currentState.memory !== newMemory) {
        // clear trumps
        const persistedKeys = Object.keys(enflatedState.persisted)
        const memoryKeys = Object.keys(enflatedState.memory)
        // console.debug(`persistedKeys: ${persistedKeys}`)
        // console.debug(`memoryKeys: ${memoryKeys}`)
        memoryKeys.forEach((key) => {
          if (persistedKeys.includes(key)) {
            // console.debug(`removing trumped ${key}`)
            delete enflatedState.persisted[key]
            newPersisted = JSON.stringify(enflatedState.persisted)
          }
        })
        currentState.memory = newMemory
      }
      if (currentState.persisted !== newPersisted) {
        currentState.persisted = newPersisted
        setCookie(localStateCookieName, newPersisted, cookieOptions)
      }
      const updatedDeflated = updatePersistedIfNeeded(enflatedState.persisted)
      if (updatedDeflated) {
        currentState.persisted = updatedDeflated
      }
      const data = {
        currentLocalState: currentState,
      }
      client.writeQuery({ query: GET_LOCAL_STATE_QUERY, data })
      // console.log(`wrote data:`)
      // console.dir(currentState)
      // console.dir(lastKnownState)
      Object.assign(lastKnownState, currentState)
      // cache.writeQuery({ query: GET_LOCAL_STATE_QUERY, data });
      return currentState
    },
  },
  Query: {
    fantasyContacts,
    userTeams,
    // currentUser: {
    //   fantasyContacts,
    //   userTeams,
    // }
  },
}

const initClient = (client: ApolloClient<any>) => {
  if (typeof window !== "undefined") {
    let timeoutId: number | undefined
    // this function will be used to seed state and to reset it when necessary
    const writeDefaults = (ignore?: boolean) => {
      timeoutId = undefined
      const data = hydrate(undefined, undefined, ignore !== true)
      // console.log(`data from hydration:`)
      // console.dir(data)
      client.writeQuery({ query: GET_LOCAL_STATE_QUERY, data })
    }
    window.addEventListener("resize", () => {
      if (!timeoutId) {
        timeoutId = setTimeout(writeDefaults, 800)
      }
    })
    client.onResetStore(() => Promise.resolve(writeDefaults(true)))
  }
}

export const createStateLink = (cache: InMemoryCache, req?: Request, res?: Response) => {
  const writeDefaults = () => {
    const data = hydrate(req, res)
    cache.writeQuery({ query: GET_LOCAL_STATE_QUERY, data })
  }
  return {
    typeDefs,
    resolvers,
    cache,
    writeDefaults,
    initClient,
  }
}
