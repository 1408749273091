import { Location } from "history"
import * as React from "react"
import { Route, Switch } from "react-router-dom"
import { TPoolRouteProps, TRouteData, TRoutes } from "../../routes.d"
import { ModalProvider } from "../components/Modal"
import LoadingView from "../components/LoadingView"
import { loadInitialProps } from "../utils/loadInitialProps"
import MetaTags from "./MetaTags"
import { getQueryWithError, getScrollContainerEl, getVGUID } from "../utils/misc-utils"
import { DefaultTheme, ThemeProvider } from "styled-components/macro"
import { ClientsEnum, ENUM_MANAGER } from "../../common/enums"
import { getRulesLink } from "../../common/game-text"
import { emptyObject } from "../../common/misc-utils"
import { poolJoinRoute, ungatedPoolSubroutesRegexp, adminMatcher } from "../../common/url-utils"
import GameOgMeta from "../App/GameOgMeta"
import Layout from "../App/Layout"
import { TAd } from "../App/Layout/typings"
import GameMarquee from "../App/PoolPages/containers/GameMarquee"
import { extractPoolSettings, buildPollingProps, extractPoolOgProps } from "../hooks/usePoolData"
import Analytics from "../utils/analytics"
import BidBarrel from "../utils/bid-barrel"
import { withoutDomain, getLayoutId, getParam, getAuthSearch, getInvitationInfo, extractPoolRoutePart, buildJoinUrl } from "../utils/url-utils"
import PoolPageArchivedBanner from "../App/Layout/components/PoolPageArchivedBanner"
import JoinPool from "../App/PoolSetupPages/containers/JoinPool"
import ErrorView from "../components/ErrorView"
import BadProdRoute from "./BadProdRoute"
import NotFound from "./NotFound"
import ManagerModeToast from "../App/Layout/components/ManagerModeToast"
import NotificationBanner from "./NotificationBanner"

interface AfterpartyProps extends TPoolRouteProps {
  data?: TRouteData
  routes: TRoutes
}

interface AfterpartyState {
  data?: TRouteData
  previousLocation: Location | null
}

class After extends React.Component<AfterpartyProps, AfterpartyState> {
  public prefetcherCache: any

  constructor(props: AfterpartyProps) {
    super(props)
    this.state = {
      data: props.data,
      previousLocation: null,
    }
    this.prefetcherCache = {}
  }

  public onRejection = (e: any) => window.SH_ERROR_NOTIFY(e)

  public componentDidMount() {
    // NOTE qac: we want to control the scroll restoration (see UNSAFE_componentWillReceiveProps below)
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual"
    }
  }
  public UNSAFE_componentWillReceiveProps(nextProps: AfterpartyProps) {
    const navigated = nextProps.location !== this.props.location
    if (navigated) {
      // want to exclude pool page tabs for ncaab bracket games form scrolling to top
      const isNcaabPoolPageTab =
        /(ncaa|ncaaw)-tournament\/bracket/.test(nextProps.location.pathname) &&
        /\/(standings|players|invite-center|message-board)/.test(nextProps.location.pathname)

      if (this.props.location.pathname !== nextProps.location.pathname && !isNcaabPoolPageTab) {
        const el = getScrollContainerEl()
        if (el === document.body || !el) {
          window.scrollTo(0, 0)
        } else {
          el.scroll({ top: 0, left: 0, behavior: "instant" as any })
        }
      }
      // save the location so we can trigger a shouldComponentUpdate lifecycle
      // NOTE qac: this is needed for a race condition that occurs using async component
      // read the render function there
      this.setState({
        previousLocation: this.props.location,
      })
      // update VGUID after change:
      getVGUID(nextProps.location.pathname)
      const { match, routes, history, location, ...rest } = nextProps
      loadInitialProps(this.props.routes, nextProps.location.pathname, {
        location: nextProps.location,
        history: nextProps.history,
        ...rest,
      })
        .then(({ data }) => {
          this.setState({ previousLocation: null, data })
        })
        .catch(this.onRejection)
    }
  }

  // shouldComponentUpdate(nextProps: any, nextState: any) {
  //   return !nextState.previousLocation;
  // }

  public prefetch = (pathname: string) => {
    loadInitialProps(this.props.routes, pathname, {
      history: this.props.history,
    })
      .then(({ data }) => {
        this.prefetcherCache = Object.assign({}, this.prefetcherCache, {
          [pathname]: data,
        })
      })
      .catch(this.onRejection)
  }

  public render() {
    const { previousLocation, data } = this.state
    const { location, poolData, match, history, routes } = this.props
    const initialData = this.prefetcherCache[location.pathname] || data
    const { params } = match
    const { search, pathname } = location

    const {
      // CENTRAL DATA
      centralGameInstancesQuery,
      centralCurrentUsersEntriesQuery,
      isCentralBracketStateLoading,
      baseIsLoading,
      // gameInstances
      gameInstancesForArea,
      gameInstanceForArea,
      gameInstanceUid,
      // pool related
      poolId,
      isArchivedPool,
      // entry related
      entryId,
      hasAutogeneratedName,
      hasManagerRole,
      isManagerModeActive,
      // user / device related
      isIosBrowser,
      isAndroidBrowser,
      isCbsAppWebview,
      hasCbsAccount,
      hasFantasyUser,

      // POOL DATA
      // queries
      poolPeriodQuery,
      centralTeamsQueryLoading,
      entryDetailsQuery,
      // loading
      entryDetailsIsLoadingOrChanging,
      memberDetailsIsLoading,
      poolDetailIsLoading,
      detailedPeriodIsLoading,
      // data
      poolDetail,
      detailedPeriod,
      detailedEntry,
      usersFirstCentralEntryInPool,
      segmentForArea,
      productSeason,
      allSegments,
      // peices:
      poolRoot,
      isInComingSoon,
      isChallengePool,
      isPoolCreate,
      centralTeams,
      deviceType,
      upsertEntryMutation,
      gameDataVariables,
    } = poolData
    const poolSettings = extractPoolSettings(poolData)
    const isInvite = search && search.indexOf("invited-by") >= 0

    const { hasFinaledGames } = buildPollingProps(poolData)
    const currentUser = centralCurrentUsersEntriesQuery.data?.currentUser || poolData.currentUser || emptyObject
    const isInDebugMode = centralCurrentUsersEntriesQuery.data?.isInDebugMode || false
    const isCbsAdmin = currentUser?.isCbsAdmin || false

    // console.dir(centralCurrentUsersEntriesQuery.data);
    const poolRoute = poolRoot ? extractPoolRoutePart(poolRoot, pathname) : undefined
    const isPoolJoinRoute = poolRoute === poolJoinRoute
    // // only display on pools this user owns
    const shouldDisplayManagerModeToast = isManagerModeActive && hasManagerRole
    // // console.log(`skipCentralPoolDetailQuery: ${skipCentralPoolDetailQuery}`, centralPoolDetailsQuery.data)
    const poolRootPathname = (poolDetail && withoutDomain(poolDetail.url)) || undefined
    const { productAbbrev, masterProductId } = productSeason || emptyObject

    // NOTE qac: we dont show the dashboard to non-cbs accounts since we want to have them log in!
    const isPoolMember = !!usersFirstCentralEntryInPool.id
    const noGamesForSport = !gameInstancesForArea.length
    const isGatedPoolSubroute = !ungatedPoolSubroutesRegexp.test(poolRoute || "")
    const isManagerPool = gameInstanceForArea && gameInstanceForArea.poolType === ENUM_MANAGER
    const showJoinPoolPage =
      !isArchivedPool && (isManagerPool || (!entryId && gameInstanceForArea?.client !== ClientsEnum.CBS)) && (!entryId || isPoolJoinRoute)
    const isManagerPoolGatedPageNoEntry = isManagerPool && !isPoolMember && isGatedPoolSubroute
    // console.log(`isManagerPoolGatedPageNoEntry: ${isManagerPoolGatedPageNoEntry} ${periodId} && ${poolId}`)
    const seasonType = productSeason && productSeason.season
    const layoutId = getLayoutId(params, seasonType)
    // const defaultOGSport = params.sportType.toLowerCase().replace("-", "");
    // NOTE qac: we want to hide ads from onboarding... so only show them on pool pages...
    const adType: TAd = (deviceType === "handheld" && "bottom") || (getParam("sh-ad-type", search) && "sh-skybox") || "skybox"
    // const adType = isHandheldPoolDashboard && "none" || deviceType === "handheld" && "bottom" || getParam("sh-ad-type", location.search) && "sh-skybox" || "skybox";
    // console.dir(requestedSeason)
    // const includedEntryIds = entry ? [entry.id] : [];
    // const poolUrl = poolDetail && poolDetail.url || poolRoot;
    const poolName = (poolDetail && poolDetail.name) || ""
    // SPOE values:
    const hasFullEntry = !hasAutogeneratedName && isPoolMember && !!poolDetail
    const entryName = (hasFullEntry && usersFirstCentralEntryInPool.name) || ""
    const rulesUrl =
      (isChallengePool &&
        productSeason &&
        getRulesLink(gameInstanceUid, productSeason.season, productSeason.productAbbrev, productSeason.year, "layout")) ||
      undefined
    const spoeProps = {
      teamLogoUrl: (hasFullEntry && usersFirstCentralEntryInPool.avatarUrl) || undefined,
      leagueName: (hasFullEntry && poolName) || undefined,
      teamName: entryName || undefined,
      currentLeagueId: (hasFullEntry && poolDetail?.id) || undefined,
    }
    // NOTE qac: ios / safari emulator is having issues with bracket switching views (white screen error), so enable body scroll
    const useBodyScroll = isCbsAppWebview && isIosBrowser && !pathname.includes("roadblock")
    const hideMobileNav = !!isPoolCreate || isCbsAppWebview
    const layoutProps = Object.assign(
      {
        layoutId,
        isChallengePool,
        isPoolMember,
        hasManagerRole,
        poolRootPathname,
        pathname,
        hideMobileNav,
        adType,
        hasCbsAccount,
        hasFantasyUser,
        isInComingSoon,
        isCbsAppWebview,
        isIosBrowser,
        isAndroidBrowser,
        rulesUrl,
        useBodyScroll,
        productAbbrev,
        deviceType,
        hasFinaledGames,
        isInDebugMode,
        poolId,
        isInvite,
        gameDataVariables,
      },
      spoeProps,
    )
    // const needsCustomGameTab = (!!detailedPeriod && detailedPeriod.usesEventGroups) || isChallengePool //  && usesSpread
    const withError = getQueryWithError([poolPeriodQuery, entryDetailsQuery, centralGameInstancesQuery, centralCurrentUsersEntriesQuery])
    const showLoading =
      poolDetailIsLoading ||
      detailedPeriodIsLoading ||
      entryDetailsIsLoadingOrChanging ||
      memberDetailsIsLoading ||
      centralTeamsQueryLoading ||
      isCentralBracketStateLoading ||
      // centralCurrentUsersEntriesQuery.loading ||
      baseIsLoading

    const isClosedPoolCreate = isPoolCreate && !(gameInstanceForArea && gameInstanceForArea.currentPeriod)
    const isClosedChallengePage = false // NOTE qac: we dont need this at all // isChallengeRoute && !challengePeriod;

    const theme = {
      id: layoutId,
      poolId,
      isInDebugMode,
      isCbsAdmin,
      isCbsAppWebview,
      isCompactMarquee: false,
      gameInstance: gameInstanceForArea,
      centralTeams,
      segment: segmentForArea,
      season: productSeason,
      poolSettings,
      // isNexusStyles: true,
    } as DefaultTheme
    Analytics.setContext({
      channel: (segmentForArea && segmentForArea.analyticsChannel) || "",
      productLine: productAbbrev,
      serviceLevel: "free",
      isCbsAppWebview,
    })
    BidBarrel.setContext({
      isCbsAppWebview,
    })
    const ogProps = extractPoolOgProps(poolData)
    const hasNoSport = noGamesForSport && !!(match && !match.params.hasOwnProperty("sportType"))
    return (
      <React.Fragment>
        <MetaTags {...location} />
        <ModalProvider>
          <ThemeProvider theme={theme}>
            <GameOgMeta {...ogProps} />
            <Layout
              {...layoutProps}
              isAdminRoute={adminMatcher.test(pathname)}
              outsideComponent={(shouldDisplayManagerModeToast && <ManagerModeToast location={location} poolId={poolId} />) || undefined}
            >
              <NotificationBanner poolData={poolData} />
              {isManagerPool && isArchivedPool && poolDetail && (
                <PoolPageArchivedBanner
                  poolDetail={poolDetail}
                  allSegments={allSegments}
                  isReinvited={!!detailedEntry?.reinvitedPassword}
                  entryId={detailedEntry?.id || null}
                  periodId={detailedPeriod?.id || null}
                  hasManagerRole={hasManagerRole}
                  hasReactivatedEntry={!!detailedEntry?.currentSeasonEntryId}
                  lastDismissedReactivateSeasonId={detailedEntry?.lastDismissedReactivateSeasonId || null}
                />
              )}
              <GameMarquee isCompactMarquee={false} poolData={poolData} location={location} history={history} match={match} routes={routes} />
              <Switch location={location}>
                {routes.map((r, i) => (
                  <Route
                    key={`route--${i}`}
                    path={r.path}
                    exact={r.exact}
                    render={(props) => {
                      if (showLoading) {
                        return <LoadingView variant="view" />
                      }
                      if (withError) {
                        return <ErrorView error={withError.error} refetch={withError.refetch} />
                      }
                      if (hasNoSport) {
                        return React.createElement(r.component, {
                          ...initialData,
                          history: props.history,
                          location: props.location, // currentLocation,
                          match: props.match,
                          prefetch: this.prefetch,
                          previousLocation,
                          poolData,
                        })
                      }
                      if (noGamesForSport) {
                        return <NotFound />
                      }
                      if (isClosedPoolCreate || isClosedChallengePage) {
                        return <BadProdRoute currentPathname={location.pathname} to={segmentForArea && segmentForArea.baseUrl} />
                      }
                      if ((showJoinPoolPage || isManagerPoolGatedPageNoEntry) && poolId && poolRoot) {
                        return (
                          <JoinPool
                            mutation={upsertEntryMutation}
                            poolId={poolId}
                            allSegments={allSegments}
                            gameInstanceUid={gameInstanceUid}
                            poolData={poolData}
                            needsAuthSearch={
                              hasCbsAccount
                                ? null
                                : getAuthSearch(buildJoinUrl({ poolRoot, search }), productAbbrev, masterProductId, isCbsAppWebview)
                            }
                            poolRoot={poolRoot}
                            isPoolJoinRoute={isPoolJoinRoute}
                            entryId={entryId}
                            joinUrl={buildJoinUrl({ poolRoot, search })}
                            {...getInvitationInfo(location.search)}
                          />
                        )
                      }
                      return React.createElement(r.component, {
                        ...initialData,
                        history: props.history,
                        location: props.location, // currentLocation,
                        match: props.match,
                        routes,
                        prefetch: this.prefetch,
                        previousLocation,
                        poolData,
                      })
                    }}
                  />
                ))}
              </Switch>
            </Layout>
          </ThemeProvider>
        </ModalProvider>
      </React.Fragment>
    )
  }
}

export default After
