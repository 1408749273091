import { TGameType, TPoolType, TSportType } from "./db-typings"
import { ClientsEnum, GameTypeEnum, PoolTypeEnum, SportTypesEnum } from "./enums"
import { ITypesEnum, TTypesEnumArray, ValidTiebreakerQuestions } from "./pool-settings"

interface IGraphqlEnumValue<T> {
  description: string
  value: T
}
interface IHash<T> {
  [key: string]: T
}

type TGraphqlValuesHash<T> = IHash<IGraphqlEnumValue<T>>

const mapTypesEnumToValues = <T>(typesEnum: Array<ITypesEnum<T>>) =>
  Object.keys(typesEnum).reduce((accum, key) => {
    const subsectionType = typesEnum[key]
    if (subsectionType.value) {
      accum[subsectionType.value] = {
        description: subsectionType.description || subsectionType.label,
        value: subsectionType.value,
      }
      // if (subsectionType.hasOwnProperty("val")) {
      //   accum[subsectionType.value].val = subsectionType.val
      // }
    }
    return accum
  }, {} as TGraphqlValuesHash<T>)

export const ClientOptions = [
  { label: "CBS Sports", value: ClientsEnum.CBS },
  { label: "Nissan", value: ClientsEnum.NISSAN },
  { label: "TESTING CBS Sports", value: ClientsEnum.TESTING_CBS },
] as TTypesEnumArray<ClientsEnum>

export const SportTypeOptions = [
  { label: "NFL", value: SportTypesEnum.NFL as TSportType, description: "National Football League" },
  { label: "NCAA Men's Basketball", value: SportTypesEnum.NCAAB as TSportType, description: "Men's NCAA Basketball" },
  { label: "NCAA Football", value: SportTypesEnum.NCAAF as TSportType, description: "Men's NCAA Football" },
  { label: "NCAA Women's Basketball", value: SportTypesEnum.NCAAW as TSportType, description: "Women's NCAA Basketball" },
] as TTypesEnumArray<SportTypesEnum>
export const GameSportType = {
  name: "GameSportTypeEnumType",
  description: `A combination of the sport with the league level.
  Examples: Nfl, NcaaBasketball, PgaTour`,
  values: mapTypesEnumToValues<SportTypesEnum>(SportTypeOptions),
}
export const GameTypeOptions = [
  {
    label: "Bracket",
    value: GameTypeEnum.BRACKET as TGameType,
    description: `Brackets involves choosing a team in a defined matchup.
  It differs from Pickem in that the user is charged with picking EVERY matchup
  in the whole tournament / bracket at once.`,
  },
  { label: "Pickem", value: GameTypeEnum.PICKEM as TGameType, description: `Pickem involves choosing a team in a heads up matchup.` },
  {
    label: "Parlay",
    value: GameTypeEnum.PARLAY as TGameType,
    description: `Parlay is similar to Pickem, with the difference being any incorrect pick nullifies all points for that Period.`,
  },
] as TTypesEnumArray<GameTypeEnum>
export const PoolTypeOptions = [
  {
    label: "Challenge",
    value: PoolTypeEnum.CHALLENGE as TPoolType,
    description: `Challenge types *have a single pool* and therefore all entries compete against eachother.
  The pool is usually administrated by an admin user.
  Challenge types are used when:
  - An overall/global leaderboard is desired for a game
  - A client desires a pool in a game, but we know that pool will probably be over the recommended maxMembers for that game type`,
  },
  {
    label: "Manager",
    value: PoolTypeEnum.MANAGER as TPoolType,
    description: `Manager types *have multiple pools* that are created by standard (non-admin) users.`,
  },
] as TTypesEnumArray<PoolTypeEnum>
const TiebreakerQuestionKeyType = {
  name: "TiebreakerQuestionKeyEnumType",
  description: `Type of attribute the Question's value should track.`,
  values: {},
}
ValidTiebreakerQuestions.forEach(({ key, label }) => {
  TiebreakerQuestionKeyType.values[key] = {
    description: label,
    value: key,
  }
})
