import React, { useState, useCallback } from "react"
import Button, { StyledButton } from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import CirclePlusSvg from "../../../../../components/icons/CirclePlus"
import BPCFFLogoPromoSvg from "../../../../../components/icons/BPCFFLogoPromo"
import { Link } from "../../../../../components/Link"
import { getAuthSearch, withoutDomain } from "../../../../../utils/url-utils"
import { TPoolRouteProps } from "../../../../../../routes.d"
import { isCurrentUserLoggedIn } from "../../../../../utils/data-utils"
import { getGameText, getRulesLink } from "../../../../../../common/game-text"
import { isNCAAWTournamentMatcher } from "../../../../../../common/common-utils-helpers"
import CreateGroup from "../../MyGroups/CreateGroup"
import { ENUM_MANAGER } from "../../../../../../common/enums"
import { BigStyledAddBracketComponent, DisclaimerWrapper, Spacer, StyledAddBracketComponent } from "./AddBracketComponent.styles"
import {
  CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node,
  CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node_pool,
} from "../../../../../../__generated__/CentralCurrentUsersEntriesQuery"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import { buildClassNames as cx } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { getStringParam, setParam } from "@cbs-sports/sports-shared-client/build/cjs/utils/url-utils"
import { EntryNameModal } from "../../../../PoolSetupPages/components/CreateEntryForm"
import GroupSvg from "../../../../../components/icons/Group"
import UpsertEntryNameModal from "../../EntryModals/UpsertEntryNameModal"
import { useDeviceType } from "../../../../../Base/DeviceType"
import { useHistory, useLocation, useRouteMatch, generatePath } from "react-router-dom"
import Analytics from "../../../../../utils/analytics"
import constants, { registeredTrademark } from "../../../../../../common/constants"
import LockSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Lock"
import WBPCFFLogoPromoSvg from "../../../../../components/icons/WBPCFFLogoPromo"

type TAddBracketVariant = "bpm" | "bpc" | "invite" | "big_bpc" | "ssbpc" | "wbpc"
interface IAddBracketComponentProps {
  variant?: TAddBracketVariant
  purpose?: "createPool" | "createBracket" | "inviteAll"
  routeProps: TPoolRouteProps
  disabled?: boolean
  useAsComponent?: boolean
  upsellText?: string
  style?: React.CSSProperties
  myVisibleEntries?: CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node[]
  pool?: CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node_pool
  onClick?: () => void
  isFromLobby?: boolean
  goToBracket?: boolean
  ftag?: string
}

interface IDisclaimerProps {
  variant: TAddBracketVariant
  url: string
}
export const Disclaimer = React.memo(({ variant, url }: IDisclaimerProps) => {
  if (variant === "big_bpc") {
    return (
      <DisclaimerWrapper>
        <>
          <span>
            No purchase necessary.&nbsp;See{" "}
            <a href={url} target="_blank" rel="noopener nofollow">
              rules
            </a>{" "}
            for details.
          </span>
        </>
      </DisclaimerWrapper>
    )
  }
  return (
    <DisclaimerWrapper className={variant}>
      {variant === "bpm" ? (
        <span data-cy="terms-text">
          See{" "}
          <a href={url} target="_blank" rel="noopener nofollow" data-cy="terms-link">
            terms
          </a>{" "}
          for details.
        </span>
      ) : (
        <>
          <span>No purchase necessary.</span>
          <span>
            &nbsp;See{" "}
            <a href={url} target="_blank" rel="noopener nofollow">
              rules
            </a>{" "}
            for details.
          </span>
        </>
      )}
    </DisclaimerWrapper>
  )
})

export const BPCLobbyLocked: React.VFC<{ message: string }> = ({ message }) => {
  return (
    <StyledAddBracketComponent className="use-as-component--true">
      <div className="information no-margin">
        <div className="icon-container">
          <LockSvg />
        </div>
        <div className="content-wrap">
          <span className="description">{message}</span>
        </div>
      </div>
    </StyledAddBracketComponent>
  )
}

const AddBracketComponent = React.memo<IAddBracketComponentProps>(
  ({
    variant = "bpc",
    purpose = "createPool",
    useAsComponent = true,
    routeProps,
    disabled = false,
    upsellText,
    style,
    myVisibleEntries,
    pool,
    onClick,
    isFromLobby = false,
    goToBracket = false,
    ftag = "",
  }) => {
    const [createEntryData, setCreateEntryData] = useState<CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node_pool | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const location = useLocation()
    const history = useHistory()
    const deviceType = useDeviceType()
    const lobbyMatch = useRouteMatch(constants.BRACKET_LOBBY_ROUTE)

    const [showModal, setShowModal] = useState(() => {
      const { search } = location
      return isFromLobby && getStringParam("modalId", search || "") === UpsertEntryNameModal.modalKey
    })

    const { poolData } = routeProps
    const {
      centralBracketState,
      currentUser,
      segmentsForArea,
      productSeason,
      gameInstanceForArea,
      gameInstancesForArea,
      upsertEntryMutation,
      myEntriesForArea,
      isCbsAppWebview,
      isInComingSoon,
      gameInstanceUid: pdGameInstanceUid,
    } = poolData
    const entries = myVisibleEntries || myEntriesForArea().currentEntriesForArea
    const isWomenBracket = isNCAAWTournamentMatcher.test(pdGameInstanceUid)
    const isInPreTournament = centralBracketState?.bracketState?.isPreTournament
    const isLocked = centralBracketState?.bracketState?.isLocked
    const isLoggedIn = isCurrentUserLoggedIn(currentUser)
    const hideModal = (created = false, entryUrl: string | undefined = undefined) => {
      const { pathname } = location
      setShowModal(false)
      if (isFromLobby && created) {
        if (entryUrl) {
          history.push(withoutDomain(entryUrl))
        }
      } else {
        history.replace(pathname)
      }
    }

    const handleOnNewEntryFinished = () => {
      setCreateEntryData(undefined)
    }

    const isCreate = Boolean(createEntryData)

    const getCreatePoolUrl = useCallback(() => {
      // if (deviceType === "handheld") {
      //   const roadblockUrl = `/games/roadblock?showCongrats=false&from=lobby&gameType=${
      //     isWomenBracket ? constants.NCAAW_MANAGER_GAME_INSTANCE_UID : constants.NCAAB_MANAGER_GAME_INSTANCE_UID
      //   }`
      //   return withoutDomain(roadblockUrl)
      // }
      const manager = gameInstancesForArea.find((g) => g.poolType === ENUM_MANAGER)
      const segment = segmentsForArea?.find((s) => s.id === manager?.currentPeriod?.segment.id)
      return segment?.landingUrl
    }, [gameInstancesForArea, segmentsForArea])

    const trackClickAction = (pool: CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node_pool) => {
      switch (pool.gameInstanceUid) {
        case constants.NCAAB_CHALLENGE_GAME_INSTANCE_UID:
        case constants.NISSAN_NCAAB_CHALLENGE_GAME_INSTANCE_UID:
          // mens-challenge
          Analytics.trackAction("challenge entry", "lobby pools", "bpc_create bracket")
          break
        case constants.NCAAW_CHALLENGE_GAME_INSTANCE_UID:
        case constants.NISSAN_NCAAW_CHALLENGE_GAME_INSTANCE_UID:
          // womens-challenge
          Analytics.trackAction("challenge entry", "lobby pools", "wbpc_create bracket")
          break
        default:
          // manager
          Analytics.trackAction("brackets welcome", "picking options", "create bracket")
          break
      }
    }

    const handleCreateBracket = React.useCallback(() => {
      if (pool) {
        trackClickAction(pool)
        const importableBrackets = entries.filter((e) => e.hasMadeAPick)
        if (importableBrackets.length > 0) {
          setCreateEntryData(pool)
        } else {
          const { poolDetail } = poolData
          if (poolDetail) {
            setIsLoading(true)
            upsertEntryMutation({
              variables: {
                poolId: poolDetail?.id,
                gameInstanceUid: poolDetail?.gameInstanceUid,
              },
            })
              .then((res) => {
                setIsLoading(false)
                // NOTE qac: if there is no onFinish, that means this component gets unmounted after success
                if (!isInComingSoon) {
                  const entry = res.data?.upsertEntry?.entry || emptyObject
                  const { url } = entry
                  history.push(withoutDomain(url))
                }
                Analytics.trackInteraction("name entry - success", { createEntry: true })
              })
              .catch((err) => {
                setIsLoading(false)
                Analytics.trackInteraction("name entry - fail")
              })
          }
        }
      } else {
        throw new Error(`Need a pool to create a bracket`)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleBPCCreateBracket = useCallback(() => {
      Analytics.trackAction("brackets welcome", "picking options", "create bracket")
      if (deviceType === "handheld") {
        const roadblockUrl = `/games/roadblock?showCongrats=false&from=lobby&gameType=${pdGameInstanceUid}`
        history.push(withoutDomain(roadblockUrl))
      } else {
        setShowModal(true)
      }
    }, [deviceType, history])
    // Note LL: Change base on business requirement from prod for creating a pool post lock
    if (isLocked && variant !== "invite" && variant !== "ssbpc") {
      return null
    }

    const segmentToUse =
      segmentsForArea &&
      productSeason &&
      segmentsForArea.find((seg) => (productSeason ? seg.season.id === productSeason.id : !!seg.season.challengePoolSlug))
    if (!segmentToUse) {
      return null
    }
    const gameInstance =
      gameInstanceForArea && variant === "bpm" && gameInstanceForArea.poolType === ENUM_MANAGER
        ? gameInstanceForArea
        : gameInstancesForArea.find((gi) => gi.id === segmentToUse.season.gameInstance.id)
    if (!gameInstance) {
      return null
    }

    const gameInstanceUid = gameInstance.uid
    const { lobbyChallengePromotionLabel } = getGameText(gameInstanceUid, segmentToUse)

    const addIcon = variant === "bpc" ? <BPCFFLogoPromoSvg /> : variant === "wbpc" ? <WBPCFFLogoPromoSvg /> : <CirclePlusSvg />
    const upsellTextToUse =
      variant === "bpc"
        ? `Fill out your bracket to compete to win a trip to the ${
            constants.MARCH_MADNESS_SPORT_YEAR + 2
          } Men's Final Four${registeredTrademark}. Enter up to 3 brackets for more chances to win.` || lobbyChallengePromotionLabel
        : variant === "wbpc"
        ? `Fill out your bracket to compete to win a trip to the ${
            constants.MARCH_MADNESS_SPORT_YEAR + 2
          } Women's Final Four${registeredTrademark}. Enter up to 3 brackets for more chances to win.`
        : upsellText || `Create an additional bracket for this pool!`
    let addBracketText = isInPreTournament ? "Join Now" : "Create Bracket"

    if (variant === "bpc" || variant === "wbpc") {
      addBracketText = isInPreTournament ? "Join Now" : "Enter Your Bracket"
    }

    const { id: poolId, gameInstanceUid: gameInstanceUidForCreate, season: seasonForCreate } = createEntryData || emptyObject

    let gameInstanceUidForDisclaimer = variant === "bpc" || variant === "big_bpc" ? "cbs-ncaab-tournament-challenge" : "cbs-ncaab-tournament-manager"
    if (isWomenBracket) {
      gameInstanceUidForDisclaimer = variant === "wbpc" ? constants.NCAAW_CHALLENGE_GAME_INSTANCE_UID : constants.NCAAW_MANAGER_GAME_INSTANCE_UID
    }
    const rulesUrlForDisclaimer =
      (productSeason && getRulesLink(gameInstanceUidForDisclaimer, productSeason.season, variant, productSeason.year, "disclaimer")) || ""

    const createBracketModal = (
      <EntryNameModal
        isOpen={isCreate}
        isCopyOrCreate
        onFinish={handleOnNewEntryFinished}
        mutation={upsertEntryMutation}
        gameInstanceUid={gameInstanceUidForCreate}
        poolId={poolId}
        entries={entries}
        season={seasonForCreate}
      />
    )

    const challlengeModal = (
      <UpsertEntryNameModal
        isOpen={showModal}
        isCreate
        redirectToLobby
        title="Name Your Bracket"
        close={hideModal}
        gameInstanceUid={gameInstanceUidForDisclaimer}
        isCommingSoon={isInPreTournament}
        mode="bpc"
        seasonType={pool?.season?.season}
        year={pool?.season?.year}
        productAbbrev="bpc"
      />
    )

    if (variant === "big_bpc") {
      let to: any
      if (!isLoggedIn && isInPreTournament) {
        const { pathname, search: currentSearch } = location
        const newSearch = setParam(currentSearch, "modalId", UpsertEntryNameModal.modalKey)
        const search = getAuthSearch(`${pathname}?${newSearch ?? ""}`, productSeason?.productAbbrev, productSeason?.masterProductId, isCbsAppWebview)
        to = {
          pathname: "/auth/login",
          search,
        }
      } else if (!(isLoggedIn && isInPreTournament) || goToBracket) {
        to = `${lobbyMatch?.url || ""}/challenge`
        if (lobbyMatch?.url && lobbyMatch.url.lastIndexOf("/") === lobbyMatch.url.length - 1) {
          // NOTE: to avoid .../bracket//challenge
          to = `${lobbyMatch.url.slice(0, -1)}/challenge`
        }
      }
      const nissanRogueImg = `${constants.DOMAIN}/static/sport-assets/Rogue.png`
      const logos = deviceType !== "handheld" && (
        <div className="logosContainer">
          <div className="finalFourLogo">
            <img src="/static/sport-assets/basketball/FinalFourLogo2023.png" />
          </div>
          <Spacer />
          <div className="sponsorLogoCont">
            <img src={nissanRogueImg} alt="Nissan Rogue" aria-label="Nissan Rogue" />
          </div>
        </div>
      )
      let ctaButton = (
        <StyledButton onClick={handleBPCCreateBracket} className="variant--primary cta">
          {addBracketText}
        </StyledButton>
      )
      if (to) {
        ctaButton = (
          <StyledButton className="variant--primary cta" as={Link} to={to}>
            Enter Bracket
          </StyledButton>
        )
      }
      return (
        <>
          {challlengeModal}
          <BigStyledAddBracketComponent className={`use-as-component--${useAsComponent}`} style={style}>
            {logos}
            <div className="promoText1">ENTER A BRACKET, WIN BIG PRIZES!</div>
            <div className="promoText2">
              Play Bracket Challenge to win a trip to the {constants.MARCH_MADNESS_SPORT_YEAR + 2} Final Four{registeredTrademark} and a Nissan Rogue!
            </div>
            {ctaButton}
            <Disclaimer variant={"big_bpc"} url={rulesUrlForDisclaimer} />
          </BigStyledAddBracketComponent>
        </>
      )
    } else if (variant === "bpc" || variant === "wbpc") {
      const titleText = `Play for Prizes`
      if (!isLoggedIn) {
        let to: any
        if (isInPreTournament) {
          const { pathname, search: currentSearch } = location
          const newSearch = setParam(currentSearch, "modalId", UpsertEntryNameModal.modalKey)
          const search = getAuthSearch(
            `${pathname}?${newSearch ?? ""}`,
            productSeason?.productAbbrev,
            productSeason?.masterProductId,
            isCbsAppWebview,
          )
          to = {
            pathname: "/auth/login",
            search,
          }
        } else {
          to = `${lobbyMatch?.url || ""}/challenge?ftag=${ftag}`
          if (lobbyMatch?.url && lobbyMatch.url.lastIndexOf("/") === lobbyMatch.url.length - 1) {
            // NOTE: to avoid .../bracket//challenge
            to = `${lobbyMatch.url.slice(0, -1)}/challenge`
          }
        }
        // NOTE LL: this would change depending on if the bracket is pickable or not
        return (
          <StyledAddBracketComponent className={`use-as-component--${useAsComponent}`} style={style}>
            <div className="information">
              <div className="icon-container icon-container--bpc">{addIcon}</div>
              <div className="content-wrap">
                <span className="title">{titleText}</span>
                <span className="description description--bpc">{upsellTextToUse}</span>
              </div>
            </div>
            <div className="button-container--bpc">
              <StyledButton className="variant--primary cta" as={Link} to={to}>
                {addBracketText}
              </StyledButton>
              <Disclaimer variant={"bpc"} url={rulesUrlForDisclaimer} />
            </div>
          </StyledAddBracketComponent>
        )
      } else if (goToBracket) {
        let to = `${lobbyMatch?.url || ""}/challenge`
        if (lobbyMatch?.url) {
          // NOTE: to avoid .../bracket//challenge
          if (lobbyMatch.url.lastIndexOf("/") === lobbyMatch.url.length - 1) {
            to = `${lobbyMatch.url.slice(0, -1)}/challenge?ftag=${ftag}`
          } else {
            to = `${lobbyMatch.url}/challenge?ftag=${ftag}`
          }
        }
        return (
          <StyledAddBracketComponent className={`use-as-component--${useAsComponent}`} style={style}>
            <div className="information">
              <div className="icon-container icon-container--bpc">{addIcon}</div>
              <div className="content-wrap">
                <span className="title">{titleText}</span>
                <span className="description description--bpc">{upsellTextToUse}</span>
              </div>
            </div>
            <div className="button-container--bpc">
              <StyledButton className="variant--primary cta" as={Link} to={to}>
                {addBracketText}
              </StyledButton>

              <Disclaimer variant={"bpc"} url={rulesUrlForDisclaimer} />
            </div>
          </StyledAddBracketComponent>
        )
      }
    } else if (variant === "ssbpc") {
      const isPretournament = constants.SWEET_16_ENABLED // centralBracketState?.bracketState.sweetSixteenBracketState.isPreTournament
      const gameText = `CBS Sports Sweet 16 Challenge`
      const message = `Bracket Busted? Sign up now for a chance to win $20k!`
      const cta = isPretournament ? "Enter Now" : "Create Bracket"

      const to = generatePath(`${constants.BRACKET_LOBBY_ROUTE}/sweet-sixteen-challenge`, {
        sportType: "college-basketball",
        subsection: "ncaa-tournament-sweet-sixteen",
        gameType: "bracket",
      })

      return (
        <StyledAddBracketComponent className={`use-as-component--${useAsComponent}`} style={style}>
          <div className="information">
            <div className="icon-container">{addIcon}</div>
            <div className="content-wrap">
              <span className="title">{gameText}</span>
              <span className="description">{message}</span>
            </div>
          </div>
          <StyledButton className="variant--primary cta" as={Link} to={to}>
            {cta}
          </StyledButton>

          <Disclaimer variant={"bpc"} url={rulesUrlForDisclaimer} />
        </StyledAddBracketComponent>
      )
    } else if (variant === "invite" && purpose === "inviteAll") {
      return (
        <StyledAddBracketComponent className={`use-as-component--${useAsComponent}`} style={style}>
          <div className="information">
            <div className="icon-container">
              <GroupSvg />
            </div>
            <div className="content-wrap">
              <span className="description">Alert previous players that your pool is back for the {productSeason?.year || ""} Tournament</span>
            </div>
          </div>
          <StyledButton className="variant--primary" onClick={onClick}>
            Invite Pool
          </StyledButton>
        </StyledAddBracketComponent>
      )
    } else {
      // BPM
      if (purpose === "createPool") {
        return (
          <StyledAddBracketComponent className={`use-as-component--${useAsComponent}`} style={style}>
            <CreateGroup createPoolUrl={getCreatePoolUrl()} disclaimerUrl={rulesUrlForDisclaimer} myVisibleEntries={myVisibleEntries} />
          </StyledAddBracketComponent>
        )
      }
    }

    return (
      <>
        {createBracketModal}
        {challlengeModal}
        <StyledAddBracketComponent
          className={cx({
            [`use-as-component--${useAsComponent}`]: true,
            [`variant--${variant}`]: true,
          })}
          style={style}
        >
          <div className="information">
            <div className={`icon-container icon-container--${variant}`}>{addIcon}</div>
            <div className="content-wrap">
              {variant === "bpc" && <span className="title">CBS Sports Bracket Challenge</span>}
              <span className="description">{upsellTextToUse}</span>
            </div>
          </div>

          {(variant === "bpc" && (
            <StyledButton onClick={handleBPCCreateBracket} className="variant--primary cta">
              {addBracketText}
            </StyledButton>
          )) || (
            <Button onClick={handleCreateBracket} disabled={disabled} withLoading loading={isLoading}>
              {addBracketText}
            </Button>
          )}
          {variant === "bpc" && <Disclaimer variant={"bpc"} url={rulesUrlForDisclaimer} />}
        </StyledAddBracketComponent>
      </>
    )
  },
)

export default AddBracketComponent
