import styled from "styled-components/macro"
import { fontWeight, palette, fontFamily, pxToRem, breakpoints } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import StyledButton from "@cbs-sports/sports-shared-client/build/cjs/components/Button/Button.styles"

export const MultipleEntriesPoolInviteMailCenterWrap = styled.div`
  display: flex;
  flex-direction: column;
  color: ${palette.gray20};
  width: 45rem;

  & > .to-section,
  .subject-section {
    border-bottom: 1px solid ${palette.gray90};
    padding: 0.5rem 0.75rem 0.5rem 1rem;
    position: relative;
    background-color: ${palette.white};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > .mail-recipients {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;
      padding: 0;
      margin-bottom: -0.25rem;
      font-family: ${fontFamily.base};
      font-size: 0.75rem;
      font-style: normal;
      font-weight: ${fontWeight.medium};
      line-height: 1;
      letter-spacing: -0.1px;
      text-align: left;
      color: ${palette.gray40};

      > .text-to {
        margin-right: 0.25rem;
        align-self: flex-start;
        font-size: ${pxToRem(14)};
        font-weight: ${fontWeight.regular};
        line-height: 1.25rem;
      }

      > .recipients-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        flex-wrap: wrap;
        flex: 1;

        > .recipient-chip {
          height: 1.25rem;
          box-sizing: border-box;
          border: 1px solid ${palette.gray90};
          border-radius: 0.125rem;
          display: flex;
          align-items: center;
          padding: 0.25rem 0 0.25rem 0.25rem;
          margin: 0 0.125rem 0.25rem;
          white-space: nowrap;

          svg {
            width: 1rem;
            height: 1rem;
            margin-left: 0.25rem;
            color: ${palette.gray50};
          }
        }

        > .recipient-chip.no-close-btn {
          padding: 0.25rem;

          svg {
            display: none;
          }
        }

        > .email-input {
          height: 1.25rem;
          padding: 0 0.25rem;
          box-sizing: border-box;
          flex: 1;
          color: ${palette.gray40};
          margin-bottom: 0.25rem;

          &:focus {
            outline: none;
          }
        }

        > .email-input.invalid {
          border: 1px solid ${palette.red};
          border-radius: 0.25rem;
        }
      }
    }
  }

  & > .actions {
    border-top: 1px solid ${palette.gray90};
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & > .invitation-error {
      color: ${palette.darkRed};
      font-size: 0.75rem;
      max-width: ${pxToRem(340)};
      text-align: left;
    }
  }

  & > .message-subject {
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    display: flex;
    border-bottom: 1px solid ${palette.gray90};

    & > .email-subject {
      width: 100%;
      font-family: ${fontFamily.base};
      font-size: 14px;
      font-style: normal;
      font-weight: ${fontWeight.regular};
      line-height: 1.25rem;
      letter-spacing: -0.1px;
      text-align: left;
      color: ${palette.gray40};
      height: 1.25rem;

      &::placeholder {
        font-family: ${fontFamily.base};
        font-size: 1rem;
        font-style: normal;
        font-weight: ${fontWeight.regular};
        line-height: 1.25rem;
        text-align: left;
        color: ${palette.gray40};
      }

      &:focus {
        outline: none;
      }
    }
  }
  & > .message-content {
    height: 18.5rem;
    padding: 1rem 1rem;
    box-sizing: border-box;

    & > .message-data {
      width: 100%;
      height: 100%;
      resize: none;

      font-family: ${fontFamily.base};
      font-size: 14px;
      font-style: normal;
      font-weight: ${fontWeight.regular};
      line-height: 1.25rem;
      letter-spacing: -0.1px;
      text-align: left;
      color: ${palette.gray20};

      &:focus {
        outline: none;
      }
    }
  }

  @media (hover: hover) {
    & > .to-section {
      > .mail-recipients {
        > .recipients-list {
          > .recipient-chip:hover:not(:active) {
            background-color: ${palette.lightestBlue};
          }
        }
      }
    }
  }

  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    width: 95vw;
  }
`
export const TabsMenuWrap = styled.div`
  position: relative;
  align-self: flex-start;
  width: 1.75rem;

  margin-top: -0.25rem;

  & > .tabs-menu-content {
    position: absolute;
    width: 20rem;
    height: 23.75rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    top: 1.75rem;
    right: 0.25rem;
    background: white;
    border-radius: 0.25rem;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1;
    overflow-y: auto;

    & > .title {
      font-family: ${fontFamily.base};
      font-size: 1rem;
      font-style: normal;
      font-weight: ${fontWeight.bold};
      line-height: 1rem;
      letter-spacing: -0.1px;
      text-align: left;
      color: ${palette.gray20};
      display: flex;
      flex-direction: row;
      padding: 1rem;
    }

    & > .menu {
      border-bottom: 1px solid ${palette.gray90};
      display: flex;
      padding: 0 1rem;
    }

    & > .contact-list {
      overflow-y: scroll;
    }
  }
`
export const TabsMenuBtn = styled(StyledButton)`
  align-self: flex-start;
  position: absolute;

  &.variant--clear.without-text--true {
    padding: 0;
    width: ${pxToRem(28)};
    height: ${pxToRem(28)};

    &:active,
    &:focus {
      background-color: transparent;
    }
  }
`
export const InviteModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  box-sizing: border-box;
  padding: 0 1rem;
  width: 100%;
  background-color: ${palette.gray97};
  border-bottom: 1px solid ${palette.gray90};

  > svg {
    width: 1.5rem;
    height: 1.5rem;
    color: ${palette.gray50};
    cursor: pointer;
  }

  & > .title {
    display: flex;
    height: 1rem;
    font-family: ${fontFamily.base};
    font-size: 1rem;
    font-style: normal;
    font-weight: ${fontWeight.bold};
    line-height: 1.5rme;
    letter-spacing: -0.1px;
    text-align: left;
    color: ${palette.gray40};
  }
`
