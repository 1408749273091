import { enum_seasonType } from "../../typings/db-models.d"
import { IPickUtilsEvent, IPickUtilsPick } from "./common-utils-types.d"
import { DbRow } from "./db-typings"
import { SeasonTypeOptions } from "./pool-settings"
import { ClientsEnum } from "./enums"

interface IFrontendOrBackendRow {
  id: string | number
}
type IStartsAt = string | Date
interface IItemWithStartsAt extends DbRow {
  startsAt: IStartsAt
}
interface IFeItemWithStartsAt {
  startsAt: number
}
interface IItemWithOrder extends IFrontendOrBackendRow {
  order: number
}
interface ISeason extends IFrontendOrBackendRow {
  season: enum_seasonType
  year: number
}
interface IPrimpySeason {
  seasonType: string
  seasonYear: number
}
interface IPrimpyObjWithSeason {
  season: IPrimpySeason
}

type FronEndSegment = {
  season: {
    gameInstance: {
      client: "CBS" | "NISSAN" | "TESTING_CBS"
    }
    season: enum_seasonType
    year: number
    id: string
  }
}

export function sortFrontendSegments(a: FronEndSegment, b: FronEndSegment) {
  const seasonAClient = a.season.gameInstance.client
  const seasonBClient = b.season.gameInstance.client
  if (seasonAClient === ClientsEnum.CBS && seasonBClient !== ClientsEnum.CBS) {
    return -1
  } else if (seasonAClient !== ClientsEnum.CBS && seasonBClient === ClientsEnum.CBS) {
    return 1
  } else {
    return getSeasonNumericValue(b.season) - getSeasonNumericValue(a.season)
  }
}
export function sortPrimpySeasons(a: IPrimpyObjWithSeason, b: IPrimpyObjWithSeason) {
  return getSeasonNumericValue(b.season) - getSeasonNumericValue(a.season)
}

export function getSeasonNumericValue(season: ISeason | IPrimpySeason) {
  const seasonType = (season as ISeason).season || ((season as IPrimpySeason).seasonType?.toLowerCase() as enum_seasonType)
  const year = (season as ISeason).year || (season as IPrimpySeason).seasonYear
  const seasonTypeOption = SeasonTypeOptions.find((opt) => opt.value === seasonType)
  const val = seasonTypeOption?.val || 0.0
  // TODO qac: we want to target ClientsEnum.CBS and prioritize that (which will default pools in NCAAB lobbies to the correct challenge)
  // const val2 = ((season as CentralSegmentFragment_season).challengePoolSlug === "challenge" && 0.01) || 0
  return year + val // + val2
}

export const oldestCreatedAtFirst = (a: DbRow, b: DbRow) => a.createdAt.getTime() - b.createdAt.getTime()

// returns: lowest id first, highest last
export const sortById = (a: DbRow, b: DbRow) => a.id - b.id

const toMs = (startsAt: IStartsAt) => new Date(startsAt).getTime()

export const sortByStartsAtInt = (a: IFeItemWithStartsAt, b: IFeItemWithStartsAt) => a.startsAt - b.startsAt

export const sortByStartsAt = (a: IItemWithStartsAt, b: IItemWithStartsAt) => toMs(a.startsAt) - toMs(b.startsAt) || a.id - b.id // || means: if thats 0

// returns 1, 2, 3, 4
export const sortByOrder = (a: IItemWithOrder, b: IItemWithOrder) => {
  return a.order - b.order
}

export const sortBySlotId = (a: IPickUtilsPick, b: IPickUtilsPick) => a.slotId.localeCompare(b.slotId)

export function newEventOfThePeriodSorter(eventOfThePeriodId: string | null, secondEventOfThePeriodId: string | null) {
  return function (a: IPickUtilsEvent, b: IPickUtilsEvent) {
    if (a?.id === eventOfThePeriodId && b?.id === secondEventOfThePeriodId) {
      return -1
    } else if (b?.id === eventOfThePeriodId && a?.id === secondEventOfThePeriodId) {
      return 1
    } else if (a?.id === eventOfThePeriodId || a?.id === secondEventOfThePeriodId) {
      return 1
    } else if (b?.id === eventOfThePeriodId || b?.id === secondEventOfThePeriodId) {
      return -1
    }
    return 0
  }
}
