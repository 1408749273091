import React, { useContext, useMemo } from "react"
import styled from "styled-components"
import BracketSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Bracket"
import ModalWrapper from "@cbs-sports/sports-shared-client/build/cjs/components/Modal/ModalWrapper"
import { fontWeight, palette, breakpoints } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { dateToString, DayFormat, emptyObject, MonthFormat, WeekdayFormat } from "@cbs-sports/sports-shared-client/build/cjs/utils"
import { NCAAB_BRACKET_SELECTION_SUNDAY_TIMESTAMP, NCAAW_BRACKET_SELECTION_MONDAY_TIMESTAMP } from "../../../../../common/constants"
import PoolDataContext, { PoolDataContextType } from "../../../../Contexts/PoolDataContext"
import { isNCAAWTournamentMatcher } from "../../../../../common/common-utils-helpers"

interface JoinedToPoolModalProps {
  onClose?: () => void
  title?: string
  message?: string
}

const LockMessageContainer = styled.div`
  width: 26rem;
  padding: 0.5rem 1.5rem 1.5rem;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: space-between;

  & > div.logo {
    position: relative;
    height: 5rem;
    width: 5rem;
    margin: 0 auto;

    svg {
      height: auto;
      max-width: 100%;
      opacity: 1;
      transition: opacity 0.5s ease-in 0.5s;
      vertical-align: top;
      margin-bottom: 0;
    }
  }

  & > div.info {
    flex: 1;
    box-sizing: border-box;
    padding: 0 0 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    width: 100%;

    & > span.title {
      margin: 0 0 0.25rem;
      font-size: 18px;
      line-height: 24px;
      font-weight: ${fontWeight.bold};
      width: 100%;
      display: block;
      color: ${palette.gray20};
    }

    & > span.lock-info {
      color: ${palette.gray40};
      font-size: 14px;
      font-weight: ${fontWeight.regular};
      line-height: 14px;
      letter-spacing: -0.1px;
    }
  }

  @media (max-width: ${breakpoints.handheld - 1}px) {
    width: 80vw;
    flex-direction: column;
    & > div.info {
      padding: 0;
      text-align: center;
    }
  }
`

const getMessage = (gameInstanceUid: string) => {
  const timeStamp = isNCAAWTournamentMatcher.test(gameInstanceUid)
    ? NCAAW_BRACKET_SELECTION_MONDAY_TIMESTAMP
    : NCAAB_BRACKET_SELECTION_SUNDAY_TIMESTAMP
  return `The bracket will be revealed on ${dateToString(new Date(timeStamp), MonthFormat.long, DayFormat.twoDigit, undefined, WeekdayFormat.long)}.`
}

const JoinedToPoolModal = ({ onClose, title = "You have joined the pool!", message }: JoinedToPoolModalProps) => {
  const poolDataContext: PoolDataContextType = useContext(PoolDataContext)
  const { gameInstanceUid } = poolDataContext || emptyObject

  const lockMessage = useMemo(() => getMessage(gameInstanceUid), [gameInstanceUid])

  return (
    <ModalWrapper modalType={"modal"} variant={"blue"} closeBtnAction={onClose} excludeShadow={false} noBorder={true}>
      <LockMessageContainer>
        <div className="logo">
          <BracketSvg />
        </div>
        <div className="info">
          <span className="title">{title}</span>
          <span className="lock-info">{message || lockMessage}</span>
        </div>
      </LockMessageContainer>
    </ModalWrapper>
  )
}

JoinedToPoolModal.modalKey = "joined-pool-modal-key"
export default JoinedToPoolModal
