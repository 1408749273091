import styled from "styled-components/macro"
import { fontFamily, fontWeight, gridBreakPoints, palette, pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
const { breakpoints } = gridBreakPoints

export const ConfirmDialogWrap = styled.div`
  box-sizing: border-box;
  background-color: ${palette.white};
  border-radius: ${pxToRem(6)};
  font-size: ${pxToRem(14)};
  opacity: 1;
  width: ${pxToRem(280)};

  & > .data-information {
    text-align: center;
    display: flex;
    flex-flow: column;
    padding: 1.5rem 1.5rem 0;
    box-sizing: border-box;

    & > .icon-wrap {
      & > svg {
        height: 2.5rem;
        width: 2.5rem;
        color: ${palette.darkRed};
      }
    }

    & > .text-container {
      margin-top: 0.5rem;
      display: flex;
      flex-flow: column;
      text-align: center;
      & > .title {
        font-family: ${fontFamily.base};
        font-size: 18px;
        font-style: normal;
        font-weight: ${fontWeight.bold};
        line-height: 1;
        letter-spacing: -0.1px;
        text-align: center;
        color: ${palette.gray20};
        margin-bottom: 0.5rem;
      }

      & > .sub-title {
        font-family: ${fontFamily.base};
        font-size: 14px;
        font-style: normal;
        font-weight: ${fontWeight.regular};
        line-height: 1;
        letter-spacing: -0.1px;
        text-align: center;
        color: ${palette.gray40};
      }
    }
  }

  & > .btn-container {
    border-top: 1px solid ${palette.gray90};
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    padding: 0.5rem;
    margin-top: 1rem;
    & > .divider {
      border-left: 1px solid ${palette.gray90};
      height: ${pxToRem(20)};
    }

    & > button:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  @media (min-width: ${pxToRem(breakpoints.sm)}) {
    width: ${pxToRem(320)};

    & > .data-information {
      flex-flow: row;

      & > .text-container {
        display: flex;
        flex-flow: column;
        margin-left: 1rem;
        margin-top: 0;
        text-align: left;

        & > .title {
          text-align: left;
        }

        & > .sub-title {
          text-align: left;
        }
      }
    }

    & > .btn-container {
      justify-content: flex-end;
      border-top: none;

      & > .divider {
        display: none;
      }
    }
  }
`
