import styled from "styled-components"
import { pxToRem, gridBreakPoints, fontFamily, fontWeight } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import bracketTheme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import ButtonOpacityInteraction from "@cbs-sports/sports-shared-client/build/cjs/components/Button/ButtonOpacityInteraction"

const { queries } = gridBreakPoints

const px6 = pxToRem(6)
const px14 = pxToRem(14)
const px28 = pxToRem(28)

export const PoolRulesModalContent = styled.div`
  width: calc(${pxToRem(488)} - 3rem);
  display: flex;
  flex-direction: column;
  color: ${bracketTheme.colors.overLight.white20};
  padding-bottom: 0.25rem;

  &.modal--wide {
    width: calc(${pxToRem(540)} - 3rem);
  }

  .modal__row {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    display: flex;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 1rem;
    }
  }

  .modal__label {
    flex: 4;
    font-weight: ${bracketTheme.fonts.weights.bold};
    font-size: ${px14};
    line-height: 1.25rem;
    letter-spacing: -0.1px;
  }

  .modal__label--height-sm {
    line-height: ${px14};
  }

  .modal__label--height-lg {
    line-height: ${px28};
  }

  .modal__content {
    flex: 8;
    margin-left: 1.5rem;
    font-weight: ${bracketTheme.fonts.weights.regular};
    font-size: ${px14};
    line-height: 1.25rem;
    letter-spacing: -0.1px;

    &.rules-content__content__constitution_true {
      line-height: 1.25rem;
      overflow-wrap: break-word;
      white-space: pre-wrap;
      overflow-x: auto;
      & a {
        color: ${bracketTheme.colors.overLight.blue2};
      }
      & a:hover {
        text-decoration: underline;
      }
    }
  }

  .modal__content--height-sm {
    line-height: ${px14};
  }

  .modal__emphasis {
    font-weight: ${bracketTheme.fonts.weights.bold};
    font-size: ${px14};
    line-height: 1.25rem;
    letter-spacing: -0.1px;
    color: ${bracketTheme.colors.overLight.white20};
    margin-bottom: ${px6};
  }

  .modal__note {
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: -0.1px;
    color: ${bracketTheme.colors.overLight.white40};
    margin-top: ${px6};
    margin-bottom: 1rem;
  }
`

export const BonusTitle = styled.div`
  display: flex;
  align-items: center;
`

export const BackArrow = styled(ButtonOpacityInteraction)`
  && {
    display: inline-block;
    margin-right: 1.25rem;
    color: ${bracketTheme.colors.overLight.white50};
    width: 1.5rem;
    height: 1.5rem;
  }
`
export const BonusItemWrapper = styled.div`
  padding: 1rem;
  border: solid 1px ${bracketTheme.colors.overLight.white90};
  border-radius: 4px;
  margin-top: ${px6};
  margin-bottom: ${px6};

  .bonusitem__title {
    font-weight: ${bracketTheme.fonts.weights.semireg};
    font-size: ${px14};
    line-height: ${px14};
    letter-spacing: -0.1px;
    color: ${bracketTheme.colors.overLight.white20};
    margin-bottom: 0.25rem;
  }

  .bonusitem__description {
    font-size: ${px14};
    line-height: 1.25rem;
    color: ${bracketTheme.colors.overLight.white40};
  }
`
export const PoolRulesTable = styled.div`
  border-radius: 0.25rem;
  border: solid 1px ${bracketTheme.colors.overLight.white90};

  .table__content {
    padding: 0.75rem 0.5rem;
  }

  .table__row {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    display: flex;
    flex-flow: row nowrap;
    font-weight: ${bracketTheme.fonts.weights.regular};
    font-size: ${px14};
    line-height: 1;
    letter-spacing: -0.1px;
    color: ${bracketTheme.colors.overLight.white20};
  }

  .table__row.table__row--dense {
    line-height: ${px14};
  }

  .table__row--title {
    font-family: ${fontFamily.condensed};
    font-weight: ${bracketTheme.fonts.weights.semireg};
    padding: 0.5rem;
    font-size: ${pxToRem(12)};
    line-height: 1;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: ${bracketTheme.colors.overLight.white50};
    border-bottom: solid 1px ${bracketTheme.colors.overLight.white90};
  }

  .table__row--title.table__row--dense {
    line-height: ${pxToRem(12)};
  }

  .table__col {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .table__col--label {
    font-weight: ${bracketTheme.fonts.weights.semireg};
  }

  .table__col--right {
    text-align: right;
  }

  .table__col--12 {
    flex: 12;
  }

  .table__col--5 {
    flex: 5;
  }

  .table__col--11 {
    flex: 11;
  }
  .table__col.table__col--label {
    font-weight: ${fontWeight.medium};
    line-height: 0.875rem;
    display: flex;
    align-items: center;
  }
`
export const TitleLink = styled(ButtonOpacityInteraction)`
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  color: ${bracketTheme.colors.overLight.blue2};

  &.disabled-mobile {
    color: ${bracketTheme.colors.overLight.white50};
    pointer-events: none;
  }

  ${queries.sm} {
    &.disabled-mobile {
      color: ${bracketTheme.colors.overLight.blue2};
      pointer-events: all;
    }
  }
`
