import { fontFamily, fontWeights, pxToRem, gridBreakPoints, fontWeight } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import styled from "styled-components/macro"
import palette from "../../../../../../common/palette"
const { queries } = gridBreakPoints

export const LimboPageWrap = styled.div`
  display: grid;
  grid-template-columns: minmax(0, auto);
  grid-auto-rows: auto;
  grid-gap: var(--grid-gap);

  & > div.pool-settings {
    color: ${palette.gray20};
    background-color: ${palette.white};
    border: 1px solid ${palette.gray90};
    box-sizing: border-box;
    padding: 1rem;

    & > .pool-settings-title {
      display: flex;
      font-family: ${fontFamily.base};
      font-size: ${pxToRem(18)};
      font-style: normal;
      font-weight: ${fontWeight.bold};
      line-height: 1.5rem;
      letter-spacing: 0px;
      text-align: left;
      color: ${palette.gray20};
      text-transform: uppercase;
      margin-bottom: 0.25rem;
    }

    & > .rules-content__row {
      --align-label: initial;
      --grid-template-columns: repeat(11, 1fr);
      --grid-template-rows: 1fr;
      --label-column-start: 1;
      --label-column-end: 12;
      --field-column-start: 1;
      --field-column-end: 12;

      display: grid;
      grid-template-columns: var(--grid-template-columns);
      grid-template-rows: var(--grid-template-rows);
      column-gap: 1rem;
      flex: 1;
      align-items: center;
      font-family: ${fontFamily.base};
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;

      & > .rules-content__label {
        grid-column-start: var(--label-column-start);
        grid-column-end: var(--label-column-end);
        cursor: initial;
        margin-top: 0;
        margin-bottom: 0.25rem;
        font-weight: ${fontWeights.bold};
        font-size: 0.875rem;
        line-height: 1;
        letter-spacing: -0.1px;
        color: ${palette.gray20};
        align-self: var(--align-label);
      }

      & > .rules-content__label.rules-content__scoring_rules {
        margin-bottom: 0.5rem;
      }

      & > .rules-content__content {
        grid-column-start: var(--field-column-start);
        grid-column-end: var(--field-column-end);
        font-size: 0.875rem;
        line-height: 1;
        border-radius: ${pxToRem(4)};
        color: ${palette.gray20};
        font-weight: ${fontWeights.regular};
        display: block;
        outline: none;
        width: 100%;
        transition: all 0.2s ease;
      }

      & > .rules-content__content.rules-content__content__constitution_true {
        line-height: 1.25rem;
        overflow-wrap: break-word;
        white-space: pre-wrap;

        & a {
          color: ${palette.blue1};
        }
        & a:hover {
          text-decoration: underline;
        }
      }
    }
  }

  ${queries.sm} {
    & > div.pool-settings {
      padding: 1rem 1.5rem 3rem;
      border-radius: 0.5rem;

      & > .pool-settings-title {
        font-size: 1.25rem;
        margin: 0.25rem 0 0.5rem;
      }

      & > .rules-content__row {
        --label-column-start: 1;
        --label-column-end: 4;
        --field-column-start: 4;
        --field-column-end: -4;

        & > .rules-content__label {
          margin: 0;
          display: flex;
        }
        & > .rules-content__label.rules-content__scoring_rules {
          margin-bottom: 0;
          line-height: 1.25rem;
          align-self: flex-start;
          line-height: 1.75rem;
        }
        & > .rules-content__label.rules-content__additional_rules {
          line-height: 1.25rem;
          align-self: flex-start;
        }
      }
      & > .rules-content__row.rules-content__additional_rules_row {
        padding-bottom: 0;
      }
    }
  }
`
