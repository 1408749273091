import palette from "./palette"
import { IThemePassin } from "./theme-helpers"

const layoutBackgroundImageStyles = {
  "college-basketball-ncaa-tournament-bracket": `radial-gradient(85% 50%, ${palette.ncaabTournamentTersery} 0%, ${palette.ncaabTournamentSecondary} 100%)`,
  default: "none",
}
const layoutBackgroundColorStyles = {
  "college-basketball-ncaa-tournament-bracket": palette.ncaabTournamentTersery,
  default: palette.defaultLightBgColor,
}
export const layoutBackgroundImageStylesSize = {
  default: "cover",
}
export const layoutBackgroundImageStylesBlendMode = {
  default: "overlay",
}
export const layoutBackgroundImageStylesPosition = {
  default: "top center",
}

export const bgColor = ({ theme }: IThemePassin) => {
  if (layoutBackgroundColorStyles[theme.id]) {
    return layoutBackgroundColorStyles[theme.id]
  }
  return layoutBackgroundColorStyles.default
}
export const bgImage = ({ theme }: IThemePassin) => {
  if (layoutBackgroundImageStyles[theme.id]) {
    return layoutBackgroundImageStyles[theme.id]
  }
  return layoutBackgroundImageStyles.default
}
export const bgSize = ({ theme }: IThemePassin) => {
  if (layoutBackgroundImageStylesSize[theme.id]) {
    return layoutBackgroundImageStylesSize[theme.id]
  }
  return layoutBackgroundImageStylesSize.default
}
export const bBlendMode = ({ theme }: IThemePassin) => {
  if (layoutBackgroundImageStylesBlendMode[theme.id]) {
    return layoutBackgroundImageStylesBlendMode[theme.id]
  }
  return layoutBackgroundImageStylesBlendMode.default
}
export const bgPosition = ({ theme }: IThemePassin) => {
  if (layoutBackgroundImageStylesPosition[theme.id]) {
    return layoutBackgroundImageStylesPosition[theme.id]
  }
  return layoutBackgroundImageStylesPosition.default
}
