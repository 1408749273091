import camelCase from "lodash/camelCase"
import kebabCase from "lodash/kebabCase"
import snakeCase from "lodash/snakeCase"
import startCase from "lodash/startCase"
import upperFirst from "lodash/upperFirst"

const parameterize = (str: string) => snakeCase(str).replace(/_/g, "-")
const upperSnakeCase = (str: string) => snakeCase(str).toUpperCase()

function getRandomChar(amount = 1) {
  return Math.random().toString(36).substr(2, amount)
}
function getRandomString(length = 12) {
  const result: string[] = []
  for (let i = 0; i < length; i++) {
    result.push(getRandomChar())
  }
  return result.join("")
}
function pseudoEncrypt(src: string) {
  let final = ""
  for (let i = 0; i < src.length; i++) {
    final += src.charAt(i) + getRandomChar()
  }
  return final
}
function pseudoDecrypt(str: string) {
  let final = ""
  for (let i = 0; i < str.length; i++) {
    final += str.charAt(i)
    i++
  }
  return final
}

function humanize(str: string) {
  return upperFirst(snakeCase(str).replace(/_/gi, " "))
}

export const pascalCase = (val: string) => {
  return startCase(camelCase(val)).replace(/ /g, "")
}

export const stringToHtmlLink = (str: string) => {
  const words = str.split(/\n/).join(" <br> ").split(/\s+/) // Using a regex to split new lines to keep spacing and join with spaces in br tags to be able to split by whitespace to get words
  const content: string[] = []
  for (const word of words) {
    const urlRegex = /(?:https?:\/\/)?(?:www\.)?((?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(\/[^\s]*)?/g
    if (urlRegex.test(word)) {
      try {
        const potentialUrl = new URL(word.startsWith("http") ? word : `http://${word}`)
        const link = `<a href="${potentialUrl.href}">${word}</a>`
        content.push(link)
      } catch (e) {
        content.push(word)
        // If the word is not a valid URL, it will throw an error which we ignore
      }
    } else {
      content.push(word)
    }
  }
  return `<p>${content.join(" ")}</p>`
}

// re-export:
export { camelCase, snakeCase, kebabCase, upperFirst, parameterize, pseudoEncrypt, pseudoDecrypt, humanize, upperSnakeCase, getRandomString }
