import { PoolDetailsFragment, PoolDetailsFragment_season_segments_edges_node } from "../__generated__/PoolDetailsFragment"

export const stepToPath = ({ path }): string => path
export const tournamentRBLabels = ["Preliminary Rounds", "Quarterfinals", "Semifinals", "Championship"]

export const getSegment = (pool: PoolDetailsFragment): PoolDetailsFragment_season_segments_edges_node => {
  const segments = pool.season.segments.edges
  return segments[0]!.node
}

export const getFullPoolSettings = (pool: PoolDetailsFragment, defaultOnly = false) => {
  const segmentPoolSettings = getSegment(pool).poolSettings
  const currentPoolSettings = Object.assign({}, segmentPoolSettings, defaultOnly ? {} : pool.poolSettings)
  if (currentPoolSettings.__typename !== "PoolSettings") {
    throw new Error(`Invalid pool settings type ${currentPoolSettings.__typename}`)
  }
  // stringify so we dont modify anything by mistake (deep objects)
  return JSON.parse(JSON.stringify(currentPoolSettings))
}
